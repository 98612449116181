import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Card, CardContent, FormControl, Grid, MenuItem, Select, colors } from '@mui/material';
import CustomTable1 from '../table/Purtable';
import SearchButton from '../search/Search';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns1 = [
  { id: 'purchase_id', label: 'Purchase ID', minWidth: 150, align:'start' },
  { id: 'product_type', label: 'Product Type', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'po_secret', label: 'PO Secret', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns2 = [
  { id: 'purchase_id', label: 'Purchase ID', minWidth: 150, align:'start' },
  { id: 'product_type', label: 'Product Type', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'po_secret', label: 'PO Secret', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns3 = [
  { id: 'purchase_id', label: 'Purchase ID', minWidth: 150, align:'start' },
  { id: 'product_type', label: 'Product Type', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'po_secret', label: 'PO Secret', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

export default function BasicTabs({ value, setValue, vehicleApiData, spareApiData, accessoriesApiData}) {

  const [status, setStatus] = React.useState('All');

  const [filteredData, setFilteredData] = React.useState(vehicleApiData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
    if(event.target.value === 'All'){
      setFilteredData(vehicleApiData);
    }
    else{
      const filtered = vehicleApiData.filter(va =>  (va.out_for_delivery ? 'Received' : va.dispatch_products ? "Dispatched" : va.assign_products ? "Products Assigned" : va.payment_verification ? "Payment Verified" : va.payment_done ? "Payment Done" : va.order_approved ? "Approved" : va.order_created ? "Pending" : "Not Created" )=== event.target.value);
      setFilteredData(filtered);
    }
  };

  React.useEffect(()=>{
    setFilteredData(vehicleApiData);
  }, [])

  const rows1 = filteredData?.map((va)=>{
    return{
      purchase_id: va.purchase_id,
      product_type:va.product_type,
      purchase_date: `${(new Date(va.created_at)).getDate()} / ${(new Date(va.created_at)).getMonth()+1} / ${(new Date(va.created_at)).getFullYear()}`,
      qty: va.item_list.length,
      po_secret: va.po_secret,
      status: va.out_for_delivery ? 'Received' : va.dispatch_products ? "Dispatched" : va.assign_products ? "Products Assigned" : va.payment_verification ? "Payment Verified" : va.payment_done ? "Payment Done" : va.order_approved ? "Approved" : va.order_created ? "Pending" : "Not Created"  
    }
  });

  const handleSearch = (query) => {
    const filtered = vehicleApiData.filter(item => item.purchase_id.includes(query));
    setFilteredData(filtered);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle Model" {...a11yProps(0)} sx={{fontWeight:'bold'}} />
          <Tab label="Spare Parts" {...a11yProps(1)} sx={{fontWeight:'bold'}}/>
          <Tab label="Accessory" {...a11yProps(2)} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box sx={{mb: 3, display:'flex', alignItems:'center'}}>
      <SearchButton handleSearch={handleSearch} placeholder="Search By Purchase ID" setFilteredData={setFilteredData}/>
      <FormControl size='small' sx={{ width: 300 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="PO Rejected">PO Rejected</MenuItem>
          <MenuItem value="Payment Done">Payment Done</MenuItem>
          <MenuItem value="Payment Verified">Payment Verified</MenuItem>
          <MenuItem value="Payment Rejected">Payment Rejected</MenuItem>
          <MenuItem value="Products Assigned">Products Assigned</MenuItem>
          <MenuItem value="Dispatched">Dispatched</MenuItem>
          <MenuItem value="Received">Received</MenuItem>
        </Select>
      </FormControl>
     </Box>
      <CustomTable1 columns={columns1} rows={rows1?.filter(obj =>{return obj.product_type === 'Vehicle'})}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CustomTable1 columns={columns2} rows={rows1?.filter(obj =>{return obj.product_type === 'Spare'})}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <CustomTable1 columns={columns3} rows={rows1?.filter(obj =>{return obj.product_type === 'Accessory'})}/>
      </CustomTabPanel>
    </Box>
  );
}