import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Button, Card, Divider, Grid, TextField, FormControl,
  RadioGroup,
  FormControlLabel,
  Radio, 
  Select,
  MenuItem, } from '@mui/material';
import CloudUpload from '../button/Cloudupload';
import { useState } from 'react';
import { useEffect } from 'react';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({priceGroups, moreData, colorGroups, variantData, setVariantData, setLoader, vehicleData, spareData, accessoriesData, setAssignedproduct, assignedproduct}) {

  const [file, setFile] = useState(null);

  const [value, setValue] = React.useState(0);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(()=>{
    if(file){
      setAssignedproduct({...assignedproduct, vehicle: {...assignedproduct.vehicle, product_img: file}})
    }
  },[file])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAssignedproduct(null);
  };

  const handleAssign = (ec) => {
    setAssignedproduct({...ec, product_type: "Vehicle"})
  }

  const handleAssign1 = (ec) => {
    setAssignedproduct({...ec, product_type: "Spare"})
  }

  const handleAssign2 = (ec) => {
    setAssignedproduct({...ec, product_type: "Accessory"})
  }

  const [variant, setVariant] = React.useState(1);

  const [colorsno, setColorsno] = React.useState(1);

  const [moreno, setMoreno] = React.useState(1);

  console.log(assignedproduct);


const operatingState = [JSON.parse(user?.billing_address)?.state];

   React.useEffect(()=>{
    setVariantData(prevItems => {
      return prevItems.map(item => {
          return { ...item, price_groups: operatingState?.map((va, index)=>{
            return {...priceGroups, state:va}
          }) };
      });
    });
 }, [])

  const handleValueChange = (name, id, event) => {
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map(item => {
        if (item.vehicle_id === id) {
          return { ...item, [name]: newValue };
        }
        return item;
      });
    });
  };

  const handlePriceChange = (name, id, state, event) => {
    console.log(name, id, state, event.target.value)
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, price_groups: variantData[index].price_groups.map(item1=>{
            if (item1.state === state) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          }) };
        }
        return item;
      });
    });
  };

  const handleColorChange = (name, id, color_id, event) => {
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, color_details: variantData[index].color_details.map(item1=>{
            if (item1.color_id === color_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          }) };
        }
        return item;
      });
    });
  };
  
  const handleFieldChange = (name, id, field_id, event) => {
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, other_details: variantData[index].other_details.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          }) };
        }
        return item;
      });
    });
  };

  const addColors = (id) => {
    variantData.map((item, index) => {
        if(item.vehicle_id === id){
          item.color_details.push({...colorGroups, color_id:colorsno+1}) };
        })
    setColorsno(colorsno+1);
  }

  const addMoreDetails = (id) => {
    variantData.map((item, index) => {
        if(item.vehicle_id === id){
          item.other_details.push({...moreData, field_id:moreno}) };
        })
    setMoreno(moreno+1);
  }

  const removeVariant = (id) =>{
      const remainData =variantData.filter((v)=> {return(v.vehicle_id !== id)});
      setVariant(variant-1);
      setVariantData(remainData);
      setValue(variant-2);
  }

  const removeColor = (id, color_id) =>{
    setColorsno(colorsno-1);
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, color_details: variantData[index].color_details.filter(item1=>{
              return (item1.color_id !== color_id) ;
          }) };
        }
        return item;
      });
    });
}

const removeField = (id, field_id) =>{
  setMoreno(moreno-1);
  setVariantData(prevItems => {
    return prevItems.map((item, index) => {
      if(item.vehicle_id === id){
        return { ...item, other_details: variantData[index].other_details.filter(item1=>{
            return (item1.field_id !== field_id) ;
        }) };
      }
      return item;
    });
  });
}

// const uniqueArray = Array.from(new Set(vehicleData.map(item => item.vehicle.id)))
//                          .map(id => {
//                              return vehicleData.find(item => item.vehicle.id === id);
//                          });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle" {...a11yProps(0)} />
          {/* <Tab label="Spare" {...a11yProps(1)} />
          <Tab label="Accessory" {...a11yProps(2)} />
          <Tab label="Manual Entry" {...a11yProps(3)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box>
      {vehicleData.map((va, index)=>{
        return(
          <Card onClick={()=> handleAssign(va)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, borderColor: assignedproduct?.variant?.id === va?.variant?.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Vehicle Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.vehicle?.product_name}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>HSN No</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.vehicle?.hsn_no}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Variant Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.variant?.variant_name}</Typography>
              </Grid>
          </Grid>
      </Card>
        )
      })}
      </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {spareData.map((va, index)=>{
        return(
          <Card onClick={()=> handleAssign1(va)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, borderColor: assignedproduct?.id === va?.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Product</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.product}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.company}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Part Code</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.part_code}</Typography>
              </Grid>
          </Grid>
      </Card>
        )
      })}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {accessoriesData.map((va, index)=>{
        return(
          <Card onClick={()=> handleAssign2(va)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, borderColor: assignedproduct?.id === va?.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Product</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.product}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.company}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Part Code</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.part_code}</Typography>
              </Grid>
          </Grid>
      </Card>
        )
      })}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
          <Grid container spacing={2} sx={{p: 2}}>
          <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={3}>
                <Typography >Product Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Product Name"
                variant="outlined"
                value={assignedproduct?.product_name}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        product_type: "Vehicle",
                        manual: true,
                        dealer_id: user?.dealer_id,
                        id: Math.round(Math.random()*100),
                        vehicle: {...assignedproduct?.vehicle, product_name: e.target.value, product_type:  "Vehicle", id: Math.round(Math.random()*100), dealer_id: user?.dealer_id },
                      })
                    }
              />
                </Grid>
                <Grid item xs={3}>
                <Typography>Type *</Typography>
                </Grid>
                <Grid item xs={3}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={assignedproduct?.type}
          onChange={(e) =>{
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, type: e.target.value},
                      })
                      setVariantData([{...variantData[0], vehicle_id: assignedproduct?.vehicle?.id, id: Math.round(Math.random()*100) }])
                      }
                    }        >
            <MenuItem value="2 Wheeler">2 Wheeler</MenuItem>
            <MenuItem value="3 Wheeler">3 Wheeler</MenuItem>
            <MenuItem value="4 Wheeler">4 Wheeler</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Registration *</Typography>
                </Grid>
                <Grid item xs={3}>
                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={assignedproduct?.registration}
          onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, registration: e.target.value},
                      })
                    } 
        >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography >HSN No *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter HSN No"
                variant="outlined"
                value={assignedproduct?.hsn_no}
          onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, hsn_no: e.target.value},
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >MFT. Year </Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.mft_year}
          onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, mft_year: e.target.value},
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >CGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.cgst}
          onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, cgst: e.target.value},
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >SGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.sgst}
          onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, sgst: e.target.value},
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >IGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.igst}
          onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        vehicle: {...assignedproduct?.vehicle, igst: e.target.value},
                      })
                    }   
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
          <Typography variant="h6">Upload Product Image</Typography>
          <CloudUpload setLoader={setLoader} file={assignedproduct?.vehicle?.product_img ? assignedproduct?.vehicle?.product_img : null} setFile={setFile}/>
          <Typography color='light' sx={{my: 2}}>Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI Maximum File Size: 1MB</Typography>
          </Grid>
          {variantData?.map((va, index)=>{
            return(
          <Grid item xs={12}>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
              <Grid item xs={2}>
                <Typography >Variant Name* </Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.variant_name}
                onChange={(e) => handleValueChange('variant_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Release Date * </Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                type='date'
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.released_date}
                onChange={(e) => handleValueChange('released_date', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Subsidy * </Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={va?.subsidy}
          onChange={(e) => handleValueChange('subsidy', va.vehicle_id, e)}
        >
            <MenuItem value="Available">Available</MenuItem>
            <MenuItem value="Un Available">Un Available</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                  </Grid>
              <Divider fullWidth sx={{my: 2}}/> 
              <Grid container spacing={2} >
              <Grid item xs={4}>
                <Typography variant='h6' >Motor Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Motor Name </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_name}
                onChange={(e) => handleValueChange('motor_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Motor Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_type}
                onChange={(e) => handleValueChange('motor_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Voltage</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_voltage}
                onChange={(e) => handleValueChange('motor_voltage', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_capacity}
                onChange={(e) => handleValueChange('motor_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km) </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_warranty_km}
                onChange={(e) => handleValueChange('motor_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months) </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_warranty_months}
                onChange={(e) => handleValueChange('motor_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.motor_warranty_type}
                onChange={(e) => handleValueChange('motor_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                <Typography variant='h6'>Battery Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Battery Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_name}
                onChange={(e) => handleValueChange('battery_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Battery Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_type}
                onChange={(e) => handleValueChange('battery_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Life Cycle</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_lifecycle}
                onChange={(e) => handleValueChange('battery_lifecycle', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_capacity}
                onChange={(e) => handleValueChange('battery_capacity', va.vehicle_id, e)}
              />
                </Grid>
                
                <Grid item xs={4}>
                <Typography >Warranty (in KM)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_warranty_km}
                onChange={(e) => handleValueChange('battery_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                
                <Grid item xs={4}>
                <Typography >Warranty (in Months) </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_warranty_months}
                onChange={(e) => handleValueChange('battery_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.battery_warranty_type}
                onChange={(e) => handleValueChange('battery_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
              <Grid item xs={4}>
                <Typography variant='h6'>Charger Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Charger Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_name}
                onChange={(e) => handleValueChange('charger_name', va.vehicle_id, e)}
              />
                              </Grid>
                              <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.charger_capacity}
                onChange={(e) => handleValueChange('charger_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Charger Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_type}
                onChange={(e) => handleValueChange('charger_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in KM)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_warranty_km}
                onChange={(e) => handleValueChange('charger_warranty_km', va.vehicle_id, e)}
              />
              </Grid>
              <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_warranty_months}
                onChange={(e) => handleValueChange('charger_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.charger_warranty_type}
                onChange={(e) => handleValueChange('charger_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                <Divider fullWidth sx={{my: 2}}/> 
              <Grid container spacing={2} sx={{alignItems:'center'}}>
              <Grid item xs={4}>
                <Typography variant='h6' >Converter Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Converter Name </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_name}
                onChange={(e) => handleValueChange('converter_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_capacity}
                onChange={(e) => handleValueChange('converter_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_warranty_km}
                onChange={(e) => handleValueChange('converter_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_warranty_months}
                onChange={(e) => handleValueChange('converter_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_warranty_type}
                onChange={(e) => handleValueChange('converter_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                <Typography variant='h6'>Controller Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Controller Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_name}
                onChange={(e) => handleValueChange('controller_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_capacity}
                onChange={(e) => handleValueChange('controller_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_warranty_km}
                onChange={(e) => handleValueChange('controller_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_warranty_months}
                onChange={(e) => handleValueChange('controller_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_warranty_type}
                onChange={(e) => handleValueChange('controller_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
              <Grid item xs={4}>
                <Typography variant='h6'>Display Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Display Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_name}
                onChange={(e) => handleValueChange('display_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Display Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_type}
                onChange={(e) => handleValueChange('display_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_warranty_km}
                onChange={(e) => handleValueChange('display_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_warranty_months}
                onChange={(e) => handleValueChange('display_warranty_months', va.vehicle_id, e)}
              />
              </Grid>
              <Grid item xs={4}>
                <Typography >Warranty Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_warranty_type}
                onChange={(e) => handleValueChange('display_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                <Divider fullWidth sx={{my: 2}}/> 
              <Grid container spacing={2} >
              <Grid item xs={4}>
                <Typography variant='h6' >Price Groups</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                  <Typography variant='p' sx={{fontSize: 14}}>
                  States
                  </Typography>
                  </Grid>
                  <Grid item xs={4}>
                  <Typography variant='p' sx={{fontSize: 14}}>
                  Ex-Showroom Price
                  </Typography>
                  </Grid>
                  <Grid item xs={4}>
                  <Typography variant='p' sx={{fontSize: 14}}>
                  Dealer Price
                  </Typography>                  
                  </Grid>
                  {va?.price_groups?.map((vaa, index)=>{
                    return(
                      <>
                    <Grid item xs={4}>
                <Typography >{vaa.state}</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vaa.ex_showroom}
                onChange={(e) => handlePriceChange('ex_showroom', va.vehicle_id, vaa.state, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vaa?.dealer}
                onChange={(e) => handlePriceChange('dealer', va.vehicle_id, vaa.state, e)}
              />
                </Grid>
                </>
                    )
                  })}
                </Grid>
                </Grid>
                <Grid item xs={4}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'start', marginBottom: 2}}>
                <Typography variant='h6'>Colour Details</Typography>
                </Box>
                {va?.color_details.map((ev, index)=>{
                  return(
                    <Grid key={index} container spacing={2} sx={{mb: 2}}>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.name}
                onChange={(e) => handleColorChange('name',  va.vehicle_id, ev.color_id, e)}
              />
                </Grid>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                type='color'
                id="outlined-basic"
                variant="outlined"
                value={ev?.code}
                onChange={(e) => handleColorChange('code', va.vehicle_id, ev.color_id, e)}
              />
                </Grid>
                {ev.color_id !== 1 &&
                <Grid item xs={1}>
                <Button onClick={()=> removeColor(va.vehicle_id, ev.color_id)}>x</Button>
                </Grid>
                }
                </Grid>
                  )
                })}
                </Grid>
                <Grid item xs={4}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'start'}}>
                <Typography variant='h6'>Other Details</Typography>
                <Button variant='text' onClick={()=>addMoreDetails(va.vehicle_id)}>+ Add more details</Button>
                </Box>
                {va?.other_details.map((ev, index)=>{
                  return(
                    <Grid key={index} container spacing={2} sx={{mb: 2}}>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.field}
                onChange={(e) => handleFieldChange('field', va.vehicle_id, ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.value}
                onChange={(e) => handleFieldChange('value', va.vehicle_id, ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Button onClick={()=> removeField(va.vehicle_id, ev.field_id)}>x</Button>
                </Grid>
                </Grid>
                  )
                })}
                </Grid>
                </Grid>
          </Grid>               
        )
          })}
          <Grid item xs={12}>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
                    <Grid item xs={6}>
                    <Typography variant="h6">
                    Product Details
                    </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end'}}>
                    </Grid>
                    <Grid item xs={1}>
                <Typography>Chassis No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.chasis_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, chasis_no: e.target.value, color:  variantData[0]?.color_details[0]?.code, id: Math.round(Math.random()*100), vechile_stocks_id: Math.round(Math.random()*100) },
                      })
                    }              />
                </Grid>
                <Grid item xs={1}>
                <Typography>MFT.Date</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                type="date"
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.mft_date}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, mft_date: e.target.value},
                      })
                    }                 />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                    Spare Details
                    </Typography>
                    </Grid>
                    <Grid item xs={1}>
                <Typography>Motor No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.motor_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, motor_no: e.target.value},
                      })
                    }              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Battery No</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.battery_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, battery_no: e.target.value},
                      })
                    }              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Controller No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.controller_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, controller_no: e.target.value},
                      })
                    }              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Converter No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.convertor_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, convertor_no: e.target.value},
                      })
                    }               />
                </Grid>
                <Grid item xs={1}>
                <Typography>Meter No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.meter_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, meter_no: e.target.value},
                      })
                    }                />
                </Grid>
                <Grid item xs={1}>
                <Typography>Key No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={assignedproduct?.stock?.key_no}
                onChange={(e) =>
                      setAssignedproduct({
                        ...assignedproduct,
                        stock: {...assignedproduct?.stock, key_no: e.target.value},
                      })
                    }                />
                </Grid>
                </Grid>
            </Grid>
          </Grid>
      </CustomTabPanel>
    </Box>
  );
}