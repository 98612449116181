import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, getinvoicebycus, getpaymentbycus, getpaymentbyid, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useReactToPrint } from 'react-to-print';


const Viewpayment = ({ setLoader, show }) => {

  const componentRef = useRef();

  const {state} = useLocation();

  const params = useParams()

  const {payment: paymentData} = useSelector((state) => state.customer) || [];

  const user = JSON.parse(localStorage.getItem('user'));



  console.log(params, state);


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  React.useEffect(()=>{
    setLoader(true)
    const id = params.paymentId;
      dispatch(getpaymentbyid(id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
    <Grid container spacing={2}>
    <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5">{params.paymentId}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={handlePrint}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}
          >

            <LocalPrintshopOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Print</Typography>
          </Grid>
    </Grid>
      <Box ref={componentRef} sx={{mt: 2, border:'1px solid #bababa', p: 3}}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
        <Grid item xs={6} sx={{display:'flex', alignItems:'center', justifyContent:'start', gap: 2}}>
                <Typography><img src={user?.logo_url} width={100}></img></Typography>
                <Box>
                <Typography variant="h6">{user.organization_name}</Typography>
                <Typography >{Object.values(JSON.parse(user?.billing_address)).join(', ')}</Typography>
                <Typography >{user.contact}</Typography>
                </Box>
            </Grid>
        <Grid item xs={6} sx={{textAlign:'end'}}>
                <Typography variant="h6">Payment Receipt</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                <Typography >Billed to</Typography>
                <Typography variant="h6">{paymentData[0]?.customer_name}</Typography>
                <Typography >{Object.values(state?.viewData?.billing_address).join(', ')}</Typography>
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'end'}}>
                <Typography >Date: {paymentData[0]?.payment_date}</Typography>
                <Typography >Payment # : {paymentData[0]?.payment_id}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Payment Date</Typography>
                <Typography >{paymentData[0]?.payment_date}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Reference Number</Typography>
                <Typography >RN - 74597</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Payment Mode</Typography>
                <Typography >{paymentData[0]?.payment_mode}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Tax Deducted</Typography>
                <Typography >{paymentData[0]?.tax_deducted}</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6" sx={{mb: 1}}>Payment for</Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>Invoice Number</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Invoice Date</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Invoice Amount</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Payment Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {paymentData[0]?.invoice_id}
              </TableCell>
              <TableCell align="right">{paymentData[0]?.payment_date}</TableCell>
              <TableCell align="right">{paymentData[0]?.amount}</TableCell>
              <TableCell align="right">{paymentData[0]?.total}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
                </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>           
            <Grid container sx={{p: 2, pb: 8, background:'#eaeaea'}}>
                  <Grid item xs={6}>
                  <Typography>Signature customer</Typography>
            </Grid>
            <Grid item xs={5} sx={{textAlign:'end'}}>
            <Typography>Signature authority</Typography>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Viewpayment;