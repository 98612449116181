import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  Card,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createproduct, createspare, getaccessory, getproduct, getspare } from "../../slices/product";
import BasicTabs from '../../components/tabs/Tabs4';
// import './style.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { createpurchase } from "../../slices/purchase";


const Newpurchase = ({ setLoader, show }) => {

  const userData = JSON.parse(localStorage.getItem('user'));

  const [distab, setDistab] = React.useState(null);

  const [value, setValue] = React.useState(0);

  const [cartcount, setCartcount] = useState(0);

  const  itemlist = {
    cart_id:0,
    vehicle_id:"",
    product_img:"",
    vechile_name: "",
    variant_id:"",
    variant_name: "",
    ordered_color: "",
    approved_color: "",
    ordered_qty: null,
    approved_qty: null,
    dealer_price: null,
    total_price: null
  }

  const [vehicledata, setVehicledata] = useState({
    product_type:"Vehicle",
    oem_id:userData.oem_id,
    dealer_id:userData.dealer_id,
    order_created: true,
    order_created_date: `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`,
    item_list:[],
  })

  console.log('vasanth', vehicledata);

  const dispatch = useDispatch();

  const {vehicles: vehicleApiData} = useSelector((state) => state.product) || [];

  const {spares: spareApiData} = useSelector((state) => state.product) || [];

  const {accessories: accessoriesApiData} = useSelector((state) => state.product) || [];


  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      dispatch(getspare())
      dispatch(getaccessory())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const navigate = useNavigate();


  const arrowBack = () => {
    navigate(-1);
  };

  const handleAddqty = (id) =>{
    setVehicledata({ ...vehicledata, item_list: vehicledata.item_list.map(item1=>{
            if (item1.cart_id === id) {
              return { ...item1,  ordered_qty: item1.ordered_qty+1, total_price: Number(item1.total_price) + Number(item1.dealer_price) };
            }
            return item1;
          }) });
  }

  const handleRemoveqty = (id) =>{
    setVehicledata({ ...vehicledata, item_list: vehicledata.item_list.map(item1=>{
            if (item1.cart_id === id) {
              return { ...item1,  ordered_qty: item1.ordered_qty-1, total_price: Number(item1.total_price) - Number(item1.dealer_price) };
            }
            return item1;
          }) });
  }

  const handleRemoveItem = (id) =>{
    setVehicledata({ ...vehicledata, item_list: vehicledata.item_list.filter(item1=>{
            return item1.cart_id !== id;
          }) });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true)
    dispatch(createpurchase(vehicledata))
    .then((data) => {
      setLoader(false);
      navigate("/purchase");
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "start" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Purchases</Typography>
          </Grid>
          <Grid item xs={8} sx={{ paddingTop: 3 }}>
          <BasicTabs vehicleApiData={vehicleApiData} spareApiData={spareApiData} accessoriesApiData={accessoriesApiData} value={value} setValue={setValue} 
            itemlist={itemlist} vehicledata={vehicledata} setVehicledata={setVehicledata}
            cartcount={cartcount} setCartcount={setCartcount} distab={distab} setDistab={setDistab}
          />
          </Grid>
          <Grid item xs={4}>
          <Card sx={{p: 2}}>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
            <Grid item xs={6}>
                <Typography variant="h6">Selected Carts</Typography>
            </Grid>
            <Grid item xs={6} sx={{textAlign:'end'}}>
                <Button onClick={()=>{
                setVehicledata({...vehicledata, item_list:[]})
                setDistab(null);
                }
                } variant="text">x Clear Cart</Button>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{my: 2}} flexItem/>
          {vehicledata.item_list.map((va, index)=>{
            if(vehicledata?.product_type === "Vehicle"){
            return(
              <div key={index} >
              <Grid container spacing={2} sx={{alignItems:'center'}}>
            <Grid item xs={4}>
                <img src={va.product_img} alt='img' width={100}/>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body1">{va.vechile_name}</Typography>
                <Box >
                <Typography variant="body1">{va.variant_name}</Typography>
                <Typography variant="body1">- {JSON.parse(vehicleApiData?.filter(ab => ab?.id === va?.vehicle_id)[0].variant?.filter(ca => ca?.id === va?.variant_id)[0]?.color_details).filter(da => da.code === va.ordered_color)[0].name}</Typography>
                </Box>
                <Typography variant="body1">₹ {va.total_price}</Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign:'end'}}>
                <Button onClick={() => { handleRemoveItem(va.cart_id)
                                  }} variant="text">x Remove</Button>
                                  <Paper elevation={0} sx={{  lineHeight:"19px", mt: 1}}>
                                  <Button sx={{minWidth: 0, background:'#dadada',}}  onClick={() => { handleRemoveqty(va.cart_id)
                                  }}
                                  ><RemoveIcon fontSize="small" sx={{color: "#000"}}/></Button>
                                  <Typography variant="p" sx={{px: 1}}>{va.ordered_qty}</Typography>
                                  <Button sx={{minWidth: 0, background:'#0b4c7c',}} onClick={() => { handleAddqty(va.cart_id)
                                  }}><AddIcon sx={{color: "#fff"}} fontSize="small" /></Button>
                                  </Paper>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{my: 2}} flexItem/>
              </div>
            )
            }
            if(vehicledata?.product_type === "Spare"){
            return(
              <div key={index} >
              <Grid container spacing={2} sx={{alignItems:'center'}}>
            <Grid item xs={4}>
                <img src={va.product_img} alt='img' width={100}/>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body1">{va.product_name}</Typography>
                <Box >
                <Typography variant="body1">{va.vehicle_name} - {va.variant.variant_name}</Typography>
                </Box>
                <Typography variant="body1">₹ {va.total_price}</Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign:'end'}}>
                <Button onClick={() => { handleRemoveItem(va.cart_id)
                                  }} variant="text">x Remove</Button>
                                  <Paper elevation={0} sx={{  lineHeight:"19px", mt: 1}}>
                                  <Button sx={{minWidth: 0, background:'#dadada',}}  onClick={() => { handleRemoveqty(va.cart_id)
                                  }}
                                  ><RemoveIcon fontSize="small" sx={{color: "#000"}}/></Button>
                                  <Typography variant="p" sx={{px: 1}}>{va.ordered_qty}</Typography>
                                  <Button sx={{minWidth: 0, background:'#0b4c7c',}} onClick={() => { handleAddqty(va.cart_id)
                                  }}><AddIcon sx={{color: "#fff"}} fontSize="small" /></Button>
                                  </Paper>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{my: 2}} flexItem/>
              </div>
            )
            }
            if(vehicledata?.product_type === "Accessory"){
            return(
              <div key={index} >
              <Grid container spacing={2} sx={{alignItems:'center'}}>
            <Grid item xs={4}>
                <img src={va.product_img} alt='img' width={100}/>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body1">{va.product_name}</Typography>
                <Box >
                <Typography variant="body1">{va.vehicle_name} - {va.variant.variant_name}</Typography>
                </Box>
                <Typography variant="body1">₹ {va.total_price}</Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign:'end'}}>
                <Button onClick={() => { handleRemoveItem(va.cart_id)
                                  }} variant="text">x Remove</Button>
                                  <Paper elevation={0} sx={{  lineHeight:"19px", mt: 1}}>
                                  <Button sx={{minWidth: 0, background:'#dadada',}}  onClick={() => { handleRemoveqty(va.cart_id)
                                  }}
                                  ><RemoveIcon fontSize="small" sx={{color: "#000"}}/></Button>
                                  <Typography variant="p" sx={{px: 1}}>{va.ordered_qty}</Typography>
                                  <Button sx={{minWidth: 0, background:'#0b4c7c',}} onClick={() => { handleAddqty(va.cart_id)
                                  }}><AddIcon sx={{color: "#fff"}} fontSize="small" /></Button>
                                  </Paper>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{my: 2}} flexItem/>
              </div>
            )
            }
          })}
          <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                  <Typography variant="h5">Total Items</Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography variant="h6">{vehicledata.item_list.length} - Items</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign:'end'}}>
                  <Typography variant="h6">{(vehicledata.item_list.map(obj => Number(obj.total_price))).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</Typography>
              </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{my: 2}} flexItem/>
          <Button onClick={handleSubmit} fullWidth type="submit" variant="contained">Place Order</Button>
          </Card>
         </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Newpurchase;
