import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import quoteService from "../services/quote.service";

export const createquote = createAsyncThunk(
  "api/createquote",
  async (stateData, thunkAPI) => {
    try {
      const data = await quoteService.createNewQuote(stateData);
      return { quotedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getquotebydealer = createAsyncThunk(
  "api/getquotebydealer",
  async (thunkAPI) => {
    try {
      const data = await quoteService.getQuotebyDealer();
      return { quotedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getquotebyid = createAsyncThunk(
  "api/getquotebyid",
  async (id, thunkAPI) => {
    try {
      const data = await quoteService.getQuotebyId(id);
      return { quotedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    quote: [],
    getQuote: "",
    message: null
}

const quoteSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createquote.fulfilled]: (state, action) => {
      state.quote.push(action.payload.quotedata.data)
      state.message = "Quote Created Successfully"
      return state;
    },
    [getquotebydealer.fulfilled]: (state, action) => {
    state.quote = action.payload.quotedata.data !== null ? action.payload.quotedata.data : []
    state.message = null
    return state;
  },
  [getquotebyid.fulfilled]: (state, action) => {
    state.getQuote = action.payload.quotedata.data
    return state;
  }
  },
});

const { reducer } = quoteSlice;
export default reducer;