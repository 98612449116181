import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, TextField, Divider } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import BasicTabs from '../../components/tabs/Tabs3';
import { getuser } from '../../slices/setting';
import SearchButton from '../../components/search/Search';
import { Link } from 'react-router-dom';

  const columns1 = [
    { id: 'user_id', label: 'User ID', minWidth: 150, align:'start' },
    { id: 'first_name', label: 'User Name', minWidth: 150, align:'start' },
    { id: 'role', label: 'User Role', minWidth: 150, align:'center' },
    { id: 'user_role', label: 'Role Cataegory', minWidth: 150, align:'center' },
    { id: 'contact', label: 'Contact', minWidth: 150, align:'center' },
    { id: 'email', label: 'Email ID', minWidth: 150, align:'center' },
    { id: 'status', label: 'Status', minWidth: 150, align:'center' },
    { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];
  

const Usermanagement = ({setLoader, show}) => {
  
  const dispatch = useDispatch();

  const settingData = useSelector((state) => state.setting);


  const [users, setUsers] = React.useState([]);


  const userUpData = users?.map((va)=>{
    return{
      ...va,
      created_at: `${(new Date(va.created_at)).getDate()} / ${(new Date(va.created_at)).getMonth()+1} / ${(new Date(va.created_at)).getFullYear()}`,
      status:'Active'
    }
  });



  React.useEffect(()=>{
    if(settingData){
          setUsers(settingData.user);
    }
  },[settingData]);

  React.useEffect(()=>{
    setLoader(true)
    dispatch(getuser())
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  },[])


  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
    <Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5'>
             User Management
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search User"/>
          <Link to='/usermanagement/createuser'>
          <Button variant='contained' size='large'>+ Create User</Button>
          </Link>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box component='div'>
        <Box>
        <CustomTable columns={columns1} setLoader={setLoader} rows={userUpData} />
        </Box>
        </Box>
      </Box> 
    </div>
  );
}

export default Usermanagement;