import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Button, Card, Divider, Grid } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({vehicleData, spareData, accessoriesData, setAssignedproduct, assignedproduct}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAssign = (ec) => {
    setAssignedproduct({...ec, product_type: "Vehicle"})
  }

  const handleAssign1 = (ec) => {
    setAssignedproduct({...ec, product_type: "Spare"})
  }

  const handleAssign2 = (ec) => {
    setAssignedproduct({...ec, product_type: "Accessory"})
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle" {...a11yProps(0)} />
          <Tab label="Spare" {...a11yProps(1)} />
          <Tab label="Accessory" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box>
      {vehicleData.map((va, index)=>{
        return(
          <Card onClick={()=> handleAssign(va)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, borderColor: assignedproduct?.id === va?.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={3}>
                  <Typography variant='body1'>Chassis No</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.chasis_no}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Motor</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.motor_no}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Battery</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.battery_no}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Controller</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.controller_no}</Typography>
              </Grid>
          </Grid>
      </Card>
        )
      })}
      </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {spareData.map((va, index)=>{
        return(
          <Card onClick={()=> handleAssign1(va)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, borderColor: assignedproduct?.id === va?.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Product</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.product}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.company}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Part Code</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.part_code}</Typography>
              </Grid>
          </Grid>
      </Card>
        )
      })}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {accessoriesData.map((va, index)=>{
        return(
          <Card onClick={()=> handleAssign2(va)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, borderColor: assignedproduct?.id === va?.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Product</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.product}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.company}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Part Code</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.stock?.part_code}</Typography>
              </Grid>
          </Grid>
      </Card>
        )
      })}
      </CustomTabPanel>
    </Box>
  );
}