import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, getpaymentbycus, updatepaymentbyid, updateservicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import { deleteproductstock, getproductstock, getserviceprice } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import BasicTabs from "../../components/tabs/servitab";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const Addserviceinvoice = ({ setLoader, show }) => {

  const {state} = useLocation();

  const [open, setOpen] = React.useState(false);

  const [file, setFile] = useState(null);

  const [assignedproduct, setAssignedproduct] = useState();

  const[gst, setGst] = useState(true);

  const [discount, setDiscount] = useState({
    val: '0',
    unit:'₹'
  })

  const [selectedItem, setSelectedItem] = useState([]);

  const {servicePrice: servicePriceData} = useSelector((state) => state.inventory) || [];

  const {payments: paymentData} = useSelector((state) => state.customer) || [];

  const user = JSON.parse(localStorage.getItem('user'));

  const dealerState =  JSON.parse(user.billing_address).state; 

  const handleOpen = () =>{
    setOpen(true);
    setLoader(true);
    dispatch(getserviceprice())
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }
  const handleClose = () =>{
    setOpen(false);
    setAssignedproduct(null);
  }

  const [customerdata, setCustomerdata] = useState({
      "dealer_id": user?.dealer_id,
      "customer_id": state?.cusData?.id,
      "customer_name": state?.cusData?.first_name,
      "invoice_date":"",
      "invoice_type":"Service",
      "due_date":"",
      "item_list":["listdata","listdata","listdata"],
      "terms_conditions":"",
      "file_url":file,
      "sub_total": "",
      "sgst":"",
      "cgst":"",
      "igst":"",
      "discount":"",
      "status":"",
      "total":"",
      "pending_payments":""
  })

  useEffect(()=>{
    if(file){
      setCustomerdata({...customerdata, file_url: file})
    }
  },[file])

  const payId = paymentData.filter(va => va.invoice_id === null).map(obj =>{return obj}).flat();

  console.log(payId);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleItemSubmit = () => {
    selectedItem.push(assignedproduct);
    setSelectedItem(selectedItem.map(obj => ({...obj, discount: obj.discount ? obj.discount : 0  })));
    handleClose();
  }

  console.log(selectedItem);

  const handledeleteItem = (id) =>{
    const remainData =selectedItem.filter((v)=> {return(v.id !== id)});
    setSelectedItem(remainData);
  }

  const handleGST = (e) => {
    setGst(e.target.checked);
  }

  const subTotal = selectedItem?.map((obj, index) => Number(obj.price)-Number(obj.price * (Number(selectedItem[index]?.discount) /100)))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const sgst = selectedItem?.map((obj) => obj.price* (obj?.sgst || 0/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const cgst = selectedItem?.map((obj) => obj.price * (obj?.cgst || 0/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const igst = selectedItem?.map((obj) => obj.price* (obj?.igst || 0/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const discVal = discount.unit === '₹' ? discount.val : subTotal*discount.val/100;

    const total = Number(subTotal)+ (gst ? (Number(cgst)+Number(sgst)+Number(igst)) : Number(0) ) - Number(discVal)


  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)
    if(payId.length>0){
      dispatch(createinvoice({...customerdata, item_list: JSON.stringify(selectedItem), sub_total:subTotal, sgst: (gst ? sgst : '0'),
      cgst: (gst ? cgst : '0'),
      igst: (gst ? igst : '0'),
      discount: discVal ,
      status: Number(payId[0].total) === Number(total) ? 'Paid':'Pending',
      invoice_type:"Service",
      total: total, pending_payments: (total-payId[0].total).toString() }))
      .then((data) => {
          const s_id = state.serviceData.service_id;
          const formData1 = {...state.serviceData, invoice_id: data.payload.customerdata.data.invoice_id, status:'Completed'}
          dispatch(updateservicebyid({s_id, formData1}))
        const formData = {invoice_id: data.payload.customerdata.data.invoice_id};
            const pay_id = payId[0].payment_id
            dispatch(updatepaymentbyid({pay_id, formData}))
        navigate(-1);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
    else{
      dispatch(createinvoice({...customerdata, item_list: JSON.stringify(selectedItem), sub_total:subTotal, sgst: (gst ? sgst : '0'),
      cgst: (gst ? cgst : '0'),
      igst: (gst ? igst : '0'),
      discount: discVal ,
      status:"Pending",
      invoice_type:"Service",
      total: total, pending_payments: total.toString() }))
      .then((data) => {
        const s_id = state.serviceData.service_id;
          const formData1 = {...state.serviceData, invoice_id: data.payload.customerdata.data.invoice_id, status:'Completed'}
          dispatch(updateservicebyid({s_id, formData1}))
        navigate(-1);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  };

  React.useEffect(()=>{
    setLoader(true)
      dispatch(getpaymentbycus(state?.cusData?.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
        <Grid container spacing={3} sx={{ 
          paddingBottom: 2, 
          alignItems: "center" 
          }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Invoice</Typography>
          </Grid>
                <Grid item xs={2}>
                <Typography >Customer Name *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.customer_name}
                InputProps={{
                readOnly: true,
              }}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography >Add GST *</Typography>
                </Grid>
                <Grid item xs={5}>
                <FormGroup>
      <FormControlLabel control={<Switch
      checked={gst}
      onChange={(e) => handleGST(e)} 
       defaultChecked />} />
      </FormGroup>
                </Grid>
                <Grid item xs={2}>
                <Typography >Invoice Date *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                type="date"
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.invoice_date}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        invoice_date: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Due Date *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                type="date"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.due_date}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        due_date: e.target.value,
                      })
                    }
              />
                </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>
        <TableContainer component={Paper} sx={{boxShadow:'none'}}>
      <Table sx={{ minWidth: 650, "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)"
    } }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Item Details</TableCell>
            <TableCell rowSpan={2} align="left">HSN</TableCell>
            <TableCell rowSpan={2} align="left">Rate</TableCell>
            <TableCell rowSpan={2} align="left">Discount</TableCell>
            <TableCell rowSpan={2} align="left">Discount Amount</TableCell>
            <TableCell colSpan={2} align="center">SGST</TableCell>
            <TableCell colSpan={2} align="center">CGST</TableCell>
            <TableCell colSpan={2} align="center">IGST</TableCell>
            <TableCell  rowSpan={2} align="left">Amount</TableCell>
            <TableCell  rowSpan={2} align="left">Action</TableCell>
          </TableRow>
          <TableRow>
            <TableCell  align="left">%</TableCell>
            <TableCell  align="left">Amount</TableCell>
            <TableCell  align="left">%</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell  align="left">%</TableCell>
            <TableCell align="left">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItem.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: "1px solid rgba(224, 224, 224, 1)" } }}
            >
              <TableCell component="th" scope="row">
              {row.service_name}
              </TableCell>
              <TableCell >{row?.hsn_no}</TableCell>
              <TableCell >{row?.price}</TableCell>
              <TableCell >
                <TextField size="small" value={row?.discount} onChange={(e)=> {
                  const newArray = [
  ...selectedItem.slice(0, index), // Copy elements before the updated object
  {...selectedItem[index], discount: e.target.value}, // Insert the updated object
  ...selectedItem.slice(index + 1) // Copy elements after the updated object
];
setSelectedItem(newArray)}
                }>

                </TextField>
              </TableCell>
              <TableCell >{row.price * (Number(selectedItem[index]?.discount) /100)}</TableCell>
              <TableCell >{gst ? (row?.sgst || 0) : '-'}</TableCell>
              <TableCell >{gst ? row.price * (row?.sgst || 0 /100) : '-'}</TableCell>
              <TableCell >{gst ? (row?.cgst || 0) : '-'}</TableCell>
              <TableCell >{gst ? row.price * (row?.cgst || 0/100) : '-'}</TableCell>
              <TableCell >{gst ? (row?.igst || 0) : '-'}</TableCell>
              <TableCell >{gst ? row.price * (row?.igst || 0/100) : '-'}</TableCell>
              <TableCell >{gst ? (Number(row?.price)+Number(row?.price * (row?.sgst || 0/100))+Number(row.price * (row?.cgst || 0/100))+Number(row.price * (row?.igst || 0/100))-Number(row.price * (Number(selectedItem[index]?.discount) /100))) : Number(row?.price)}</TableCell>
              <TableCell>
              <Button
                                          sx={{
                                            background: "#dc0707",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={() => handledeleteItem(row.id)}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                  <Button onClick={() => handleOpen()} variant="text" size="small" sx={{my: 3}}>+ Add Item</Button>
                  <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <BasicTabs vehicleData={servicePriceData} setAssignedproduct={setAssignedproduct} assignedproduct={assignedproduct}/>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                            <Button variant="outlined"
                            onClick={handleClose}
                            sx={{mr: 1}}
                            >
                                Cancel
                              </Button>
                              <Button onClick={handleItemSubmit} variant="contained">
                                Confirm
                              </Button>
                            </Box>
                        </Box>
                      </Modal>            
    <Grid container spacing={2}>
          <Grid item xs={9} sx={{textAlign:'end'}}>
              <Typography variant="body1">Sub Total</Typography>
              <Typography  variant="body1">SGST</Typography>
              <Typography  variant="body1">CGST</Typography>
              <Typography  variant="body1">IGST</Typography>
              <Typography  variant="body1" sx={{mt: 1}}>Adjustment</Typography>
              <Typography  variant="h6" sx={{mt: 2}}>Total (₹)</Typography>
          </Grid>
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1">₹ {subTotal ? subTotal : '0.00'}</Typography>
              <Typography variant="body1">₹ {(sgst && gst) ? sgst :'0.00'}</Typography>
              <Typography variant="body1">₹ {(cgst && gst) ? cgst :'0.00'}</Typography>
              <Typography variant="body1">₹ {(igst && gst) ? igst :'0.00'}</Typography>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap: 1}}>
              <TextField sx={{width:'80px'}} size="small" 
              value={discount?.val}
              onChange={(e)=>{
                setDiscount({...discount, val: e.target.value})
              }}
              id="outlined-basic" variant="outlined" />
              <FormControl size="small">
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={discount.unit}
    onChange={(e)=>{
                setDiscount({...discount, unit: e.target.value})
              }}
  >
    <MenuItem value={'₹'}>₹</MenuItem>
    <MenuItem value={'%'}>%</MenuItem>
  </Select>
</FormControl>
              <Typography variant="body1">₹ {discVal ? discVal :'0.00'}</Typography>

              </Box>
              <Typography variant="h6" sx={{mt: 2}}>₹ {total ? total :'0.00'}</Typography>
          </Grid>
    </Grid>

            <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                <Typography variant="h6">Terms & Conditions *</Typography>
                <TextField
                sx={{mt: 2}}
                rows={4}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.terms_conditions}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        terms_conditions: e.target.value,
                      })
                    }
              />
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6">Attach files to estimate *</Typography>
                <CloudUpload setLoader={setLoader} file={customerdata.file_url} setFile={setFile}/>
                </Grid>
            </Grid>
            <Divider sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create New Invoice</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Addserviceinvoice;