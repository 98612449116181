import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/ChallanTable';
import Addchallan from './Addchallan';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import { Link } from 'react-router-dom';
import { getchallan } from '../../slices/challan';
import Popup from '../../components/popup/Popup';

const columns = [
    { id: 'delivery_challan', label: 'Delivery Challan', minWidth: 150, align:'start' },
    { id: 'dealer_name', label: 'Customer Name', minWidth: 150, align:'start' },
    { id: 'delivery_challan_date', label: 'Date', minWidth: 150, align:'center' },
    { id: 'reference', label: 'Reference#', minWidth: 150, align:'center' },
    { id: 'invoicestatus', label: 'Invoice Status', minWidth: 150, align:'center' },
    { id: 'total_price', label: 'Amount', minWidth: 150, align:'center' },
    { id: 'status', label: 'Status', minWidth: 150, align:'center' },
    { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];
  
  const rows = [
    {dchallan: '01', cname: 'Cow', date: 'url', reference: 'Coimbatore', invoicestatus:'GSTIN0008765', amount:'$500', status:'Active'},
    {dchallan: '01', cname: 'Cow', date: 'url', reference: 'Coimbatore', invoicestatus:'GSTIN0008765', amount:'$500', status:'Active'}
  ];

const Challan = ({setLoader, show}) => {

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const {challan: challanData} = useSelector((state) => state.challan) || [];

  const challanUpdata = challanData?.map((va)=>{
    return{
      ...va,
      invoicestatus:'Open',
      status:'Active'
    }
  })

  const dispatch = useDispatch();

  const {message: message} = useSelector((state) => state.challan);

  console.log(message);

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  useEffect(()=>{
    setLoader(true)
      dispatch(getchallan())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
              <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            Delivery Challans
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          {/* <SearchButton placeholder="Search Here"/> */}
          <Link to='/deliverychallan/addchallan'>
          <Button variant='contained' size='large' >+ Create New Challans</Button>
          </Link>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable setLoader={setLoader} columns={columns} rows={challanUpdata ? challanUpdata : []} />
      </Box>
    </div>
  );
}

export default Challan;