import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { createenquiry, getenquiry } from "../../slices/enquiry";
import { getregion } from "../../slices/setting";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import BasicTabs from "../../components/tabs/Customertab";
import { deletecustomer, getcustomer, getinvoicebycus } from "../../slices/customer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Popup from "../../components/popup/Popup";

const Viewcustomer = ({ setLoader, show }) => {

  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const id = params.id;

  const {customers: enquiryData} = useSelector((state) => state.customer) || [];

  const {invoices: invoiceData} = useSelector((state) => state.customer) || [];


  const viewData =  enquiryData.filter(obj => obj.id === Number(id))[0] || [];

  const vehicledetailsData = (invoiceData.filter(va => va.invoice_type !== "Service")).map(obj => JSON.parse(obj.item_list)).flat();

  const vehicleSelectOption = vehicledetailsData.map(obj => {return {
    id: obj.id,
    vehicle_name: obj?.vehicle?.product_name,
    variant_name: obj?.variant?.variant_name
  }})

  const dispatch = useDispatch();

  const {message: message} = useSelector((state) => state.customer);

  console.log(message);

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const deleteCustomer = (c_id) =>{
    if(c_id){
      setLoader(true)
      dispatch(deletecustomer({c_id}))
      .then(() => {
        navigate(-1);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  }

  useEffect(()=>{
    setLoader(true)
      dispatch(getcustomer())
      dispatch(getinvoicebycus(id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
          <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>View Customer</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ paddingTop: 3, display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6">
              {viewData.title} {viewData.first_name} {viewData.last_name}
            </Typography>
            <Typography
              variant="p"
              sx={{
                bgcolor: "#0b4c7c",
                color: "#fff",
                px: 1,
                borderRadius: "10px",
                ml: 1,
              }}
            >
              {viewData.enquiry_type}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "end" }}>
          <Link to={`/customer/update/${viewData.id}`} state={{viewData: viewData}}>
          <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 1,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
                                        </Link>
                                        <Button
                                          onClick={()=> deleteCustomer(viewData.id)}
                                          sx={{
                                            background: "#dc0707",
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Customer Join</Typography>
            <Typography variant="body1">{`${new Date(viewData.created_at).getDate()}-${new Date(viewData.created_at).getMonth()+1}-${new Date(viewData.created_at).getFullYear()}`}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Customer Type</Typography>
            <Typography variant="body1">{viewData.customer_type}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">No. of Vehicle</Typography>
            <Typography variant="body1">{vehicleSelectOption?.length}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "end" }}>
            <Box>
            <a href={`tel:${viewData.phone}`}>
              <Button
                sx={{
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                  border:'1px solid #3d07dc'
                }}
              >
                <CallIcon sx={{ color: "#3d07dc", fontSize: 17 }}></CallIcon>
              </Button>
              </a>
              <a href={`mailto:${viewData.email}`}>
              <Button
                sx={{
                  border:'1px solid #3d07dc',
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                }}
              >
                <MessageIcon sx={{ color: "#3d07dc", fontSize: 17 }}></MessageIcon>
              </Button>
              </a>
              <a href={`mailto:${viewData.email}`}>
              <Button
                sx={{
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                  border:'1px solid #3d07dc',
                }}
              >
                <EmailIcon sx={{ color: "#3d07dc", fontSize: 17 }}></EmailIcon>
              </Button>
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <BasicTabs cusId={id} viewData={viewData} setLoader={setLoader}/>
            </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Viewcustomer;
