import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, Card } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import BasicTabs from '../../components/tabs/Tabs1';
import { Link } from 'react-router-dom';
import { getaccessory, getproduct, getspare} from '../../slices/product';
import { getaccessorystock, getproductstock, getsparestock } from '../../slices/inventory';
import Popup from '../../components/popup/Popup';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ElectricMopedOutlinedIcon from '@mui/icons-material/ElectricMopedOutlined';
import Chart from "react-apexcharts";
import { getpurchase } from '../../slices/purchase';
import { getcustomer, getinvoicebydealer, getwarrantybydealer } from '../../slices/customer';

const options = {
  chart: {
    height: 350,
    type: 'area'
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'month',
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy HH:mm'
    },
  },
}

const options1 = {
              labels:['Pending', 'Processing', 'Completed'],
              chart: {
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
              dataLabels: {
                enabled: false
              },
              fill: {
                type: 'gradient',
              },
              legend: {
                formatter: function(val, opts) {
                  return val + " - " + opts.w.globals.series[opts.seriesIndex]
                }
              },
              title: {
                text: 'Purchase Order'
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            }


const Dashboard = ({setLoader, show}) => {

const [series, setSeries]= useState([{
  name: 'Orders',
  data: []
}, {
  name: 'Warranty Claim',
  data: []
}])

const [series1, setSeries1] = useState([])


  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const {customers: customer} = useSelector((state) => state.customer);

  const {dealerwarrantys: warrantyData} = useSelector((state) => state.customer);

  const {dealerinvoices: invoiceData} = useSelector((state) => state.customer);

  const {purchases: purchaseData} = useSelector((state) => state.purchase) || [];

  const {vehicles: vehicleStockData} = useSelector((state) => state.inventory) || [];


  const {vehicles: vehicleApiData} = useSelector((state) => state.product) || [];


  const dispatch = useDispatch();

  function filterObjectsByMonth(data, targetMonth) {
    return data?.filter(item => {
      const date = new Date(item?.created_at);
      return date.getMonth() === targetMonth - 1; // JavaScript months are 0-indexed
    });
  }
  
  useEffect(()=>{
    setSeries(prevItems => {
      return prevItems.map(item => {
        if(item.name === "Orders"){
          const upData = [];
          for(let i=0; i < 12; i++){
            const filteredData = filterObjectsByMonth(purchaseData, i+1);
            if(filteredData?.length > 0){
            upData.push(filteredData?.length)
            }
            else{
              upData.push(0)
            }
          }
          return { ...item, 
            data:  upData}
        }
        return item;
          }) });

          const upData = [];
          for(let i=0; i < 3; i++){
            const filteredData = purchaseData?.filter(va =>  va.order_approved === 0);
            const filteredData1 = purchaseData?.filter(va =>  va.dispatch_products === 0);
            const filteredData2 = purchaseData?.filter(va =>  va.out_for_delivery === 1);
            if(i === 0){
            upData.push(filteredData?.length)
            }
            if(i === 1){
              upData.push(filteredData1?.length)
              }
              if(i === 2){
                upData.push(filteredData2?.length)
                }
          }

          setSeries1(upData);
 }, [purchaseData])

 const soldVehicle = invoiceData?.map(obj => JSON.parse(obj?.item_list)).flat();

 useEffect(()=>{
  setSeries(prevItems => {
    return prevItems.map(item => {
      if(item.name === "Warranty Claim"){
        const upData = [];
        for(let i=0; i < 12; i++){
          const filteredData = filterObjectsByMonth( warrantyData, i+1);
          if(filteredData?.length > 0){
          upData.push(filteredData?.length)
          }
          else{
            upData.push(0)
          }
        }
        return { ...item, 
          data:  upData}
      }
      return item;
        }) });
}, [warrantyData])

  useEffect(()=>{
    setLoader(true)
      dispatch(getcustomer())
      dispatch(getpurchase())
      dispatch(getwarrantybydealer())
      dispatch(getinvoicebydealer())
      dispatch(getproductstock())
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
          <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
             Dashboard
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>

          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} sx={{mb: 2}}>
          <Grid item xs={3}>
            <Card className='dashCard dashCard1' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Box>
              <Typography variant='h4' sx={{fontWeight:'bold'}}>{customer?.length}</Typography>
                <Typography variant='h6' sx={{mt: 1}}>No of Customers</Typography>
            </Box>
            <Box sx={{background:'#eef8ff', p: 1.5, borderRadius: 2}}>
              <PeopleAltOutlinedIcon sx={{fontSize: 30, color:'#0b4c7c'}}/>
              </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className='dashCard dashCard2' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Box>
              <Typography variant='h4' sx={{fontWeight:'bold'}}>{soldVehicle?.length}</Typography>
                <Typography variant='h6' sx={{mt: 1}}>Vehicle Sold</Typography>
            </Box>
            <Box sx={{background:'#f0fff6', p: 1.5, borderRadius: 2}}>
              <ElectricMopedOutlinedIcon sx={{fontSize: 30, color:'#02bb4c'}}/>
              </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className='dashCard dashCard3' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Box>
              <Typography variant='h4' sx={{fontWeight:'bold'}}>{vehicleStockData?.length}</Typography>
                <Typography variant='h6' sx={{mt: 1}}>Vehicle Stock</Typography>
            </Box>
            <Box sx={{background:'#d9efff', p: 1.5, borderRadius: 2}}>
              <InventoryOutlinedIcon sx={{fontSize: 30, color:'#0093ff'}}/>
              </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className='dashCard dashCard4' sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Box>
              <Typography variant='h4' sx={{fontWeight:'bold'}}>{purchaseData?.length}</Typography>
                <Typography variant='h6' sx={{mt: 1}}>Purchases</Typography>
            </Box>
            <Box sx={{background:'#fef1ff', p: 1.5, borderRadius: 2}}>
              <ShoppingCartOutlinedIcon sx={{fontSize: 30, color:'#ec00ff'}}/>
              </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <div className="app">
        <div className="row">
          <div className="chart">
            <Chart
              options={options}
              series={series}
              type="area"
              height='300'
            />
          </div>
        </div>
      </div>
            </Card>
          </Grid>
          <Grid item xs={5}>
          <Card sx={{boxShadow:'none', p: 3, border:'1px solid #cacaca'}}>
            <div className="app">
        <div className="row">
          <div className="chart">
            <Chart
              options={options1}
              series={series1}
              type="donut"
              height='310'
            />
          </div>
        </div>
      </div>
            </Card>
          </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;