import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography, Card } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import BasicTabs from '../../components/tabs/Tabs1';
import { Link } from 'react-router-dom';
import { getaccessory, getproduct, getspare} from '../../slices/product';
import { getaccessorystock, getproductstock, getserviceprice, getsparestock } from '../../slices/inventory';

const Inventory = ({setLoader, show}) => {

  const [value, setValue] = React.useState(0);

  const {vehicles: vehicleStockData} = useSelector((state) => state.inventory) || [];

  const {spares: spareStockData} = useSelector((state) => state.inventory) || [];

  console.log(vehicleStockData);

  const {accessories: accessoriesStockData} = useSelector((state) => state.inventory) || [];

  const {servicePrice: servicePriceData} = useSelector((state) => state.inventory) || [];

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getproductstock())
      dispatch(getsparestock())
      dispatch(getaccessorystock())
      dispatch(getserviceprice())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
             Inventory
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mb: 2}}>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Vehicle Stocks</Typography>
                <Typography variant='p'>{vehicleStockData?.length}</Typography>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Spare Stocks</Typography>
                <Typography variant='p'>{spareStockData?.length}</Typography>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Accessory Stocks</Typography>
                <Typography variant='p'>{accessoriesStockData?.length}</Typography>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Service Price</Typography>
                <Typography variant='p'>{servicePriceData?.length}</Typography>
            </Card>
          </Grid>
      </Grid>
      </Box>
      <Box>
        <BasicTabs setLoader={setLoader} vehicleStockData={vehicleStockData} spareStockData={spareStockData} accessoriesStockData={accessoriesStockData} servicePriceData={servicePriceData} value={value} setValue={setValue}/>
      </Box>
    </div>
  );
}

export default Inventory;