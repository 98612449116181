import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, createservice, createwarranty, getinvoicebycus, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import { toBeRequired } from "@testing-library/jest-dom/matchers";


const Addwarranty = ({ setLoader, show }) => {

  const {state} = useLocation();

  const {invoices: invoiceData} = useSelector((state) => state.customer) || [];

  const [file, setFile] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));


  const vehicledetailsData = (invoiceData.filter(va => va.invoice_type !== "Service")).map(obj => JSON.parse(obj.item_list)).flat();

  const vehicleSelectOption = vehicledetailsData.filter(ab => ab.product_type === "Vehicle" || !ab.hasOwnProperty("product_type")).map(obj => {return {
    id: obj.id,
    vehicle_name: obj.vehicle.product_name,
    variant_name: obj.variant.variant_name
  }})

  console.log(user, vehicledetailsData);

  const [customerdata, setCustomerdata] = useState({
      "dealer_id": user?.dealer_id,
      "customer_id": state?.cusData?.id,
      "customer_name": state?.cusData?.first_name,
       "oem_id": user?.oem_id,
      "dealer_name": user?.first_name,
      "product_type": "Vehicle",
      "select_vehicle":"",
      "hsn_no":"",
      "chasis_number":"",
      "item":"",
      "item_no":"",
      "item_brand":"",
      "still_warranty":"",
      "capacity":"",
      "issue":"",
      "others":"",
      "remarks":"",
      "file_url": file,
      "warranty_created": true,
      "warranty_created_date":`${(new Date()).getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate().toString().padStart(2, '0'))}`,
      })


      useEffect(()=>{
        if(file){
          setCustomerdata({...customerdata, file_url: file})
        }
      },[file])

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!customerdata.product_type || !customerdata.select_vehicle || !customerdata.chasis_number || !customerdata.item || !customerdata.item_no || !customerdata.item_brand || !customerdata.still_warranty || !customerdata.capacity || !customerdata.issue || !customerdata.remarks || !customerdata.file_url ) {
      window.alert('Please fill out all required fields.');
    } else {
    setLoader(true)

    dispatch(createwarranty({...customerdata, customer_id: (customerdata.customer_id).toString(), hsn_no: (customerdata.hsn_no).toString(), select_vehicle: `${vehicleSelectOption.filter(obj => obj.id === customerdata.select_vehicle)[0].vehicle_name}: ${vehicleSelectOption.filter(obj => obj.id === customerdata.select_vehicle)[0].variant_name}` }))
    .then(() => {
      navigate(-1);
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }
  };

  
  React.useEffect(()=>{
    setLoader(true)
      dispatch(getinvoicebycus(state?.cusData?.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>Claim New Warranty</Typography>
          </Grid>
                <Grid item xs={12}>
                <FormControl fullWidth
                >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={customerdata.product_type}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        product_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Vehicle"
                    control={<Radio />}
                    label="Vehicle"
                  />
                  <FormControlLabel
                    value="Spares"
                    control={<Radio />}
                    label="Spares"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={2}>
                <Typography >Customer Name *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.customer_name}
                InputProps={{
                readOnly: true,
              }}
              />
                </Grid>
          <Grid item xs={2}>
                <Typography >Select Vehicle *</Typography>
                </Grid>
                <Grid item xs={4}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.select_vehicle}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        select_vehicle: e.target.value,
                        chasis_number: vehicledetailsData.filter(obj => obj.id === e.target.value)[0]?.stock?.chasis_no,
                        hsn_no: vehicledetailsData.filter(obj => obj.id === e.target.value)[0]?.vehicle?.hsn_no,
                      })
                    }
  >
  {vehicleSelectOption.map((va,index)=>{
    return(
      <MenuItem key={index} value={va.id}>{va.vehicle_name}: {va.variant_name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
                </Grid>
                <Grid item xs={2}>
                <Typography >Chasis Number *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.chasis_number}
                InputProps={{
                readOnly: true,
              }}
              />
                </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>   
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <Typography variant="h6">Item Details</Typography>
          </Grid>
          <Grid item xs={2}>
                <Typography >Select Item *</Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.item}
                onChange={(e) =>{
                      const currentDate = new Date();
                      currentDate.setMonth(currentDate.getMonth() + (e.target.value === 'Motor' ? Number(vehicledetailsData?.filter(obj => obj?.id === customerdata?.select_vehicle)[0]?.variant?.motor_warranty_months) : e.target.value === 'Battery' ? Number(vehicledetailsData?.filter(obj => obj.id === customerdata?.select_vehicle)[0]?.variant?.battery_warranty_months) :  e.target.value === 'Charger' ? Number(vehicledetailsData?.filter(obj => obj.id === customerdata?.select_vehicle)[0]?.variant?.charger_warranty_months) : e.target.value === 'Converter' ? Number(vehicledetailsData?.filter(obj => obj.id === customerdata?.select_vehicle)[0]?.variant?.converter_warranty_months) : null));

                      console.log(currentDate);

                      setCustomerdata({
                        ...customerdata,
                        item: e.target.value,
                        item_no: e.target.value === 'Motor' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.stock?.motor_no : e.target.value === 'Battery' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.stock?.battery_no : e.target.value === 'Charger' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.stock?.key_no : e.target.value === 'Converter' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.stock?.convertor_no : null,
                        item_brand: e.target.value === 'Motor' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.motor_name : e.target.value === 'Battery' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.battery_name : e.target.value === 'Charger' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.charger_name : e.target.value === 'Converter' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.converter_name : null,
                        capacity: e.target.value === 'Motor' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.motor_capacity : e.target.value === 'Battery' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.battery_capacity : e.target.value === 'Charger' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.charger_capacity : e.target.value === 'Converter' ? vehicledetailsData.filter(obj => obj.id === customerdata.select_vehicle)[0]?.variant?.converter_capacity : null,
                        still_warranty: currentDate?.toISOString().slice(0,10)
                      })}
                    }
  >
<MenuItem value="Motor">Motor</MenuItem>
<MenuItem value="Battery">Battery</MenuItem>
<MenuItem value="Charger">Charger</MenuItem>
<MenuItem value="Converter">Converter</MenuItem>
    {/* {(state.warrantyData).filter(va => va.chasis_number === customerdata.chasis_number)?.filter( obj => obj.item === 'Motor')?.length === 0 ? <MenuItem value="Motor">Motor</MenuItem> : null}
    {(state.warrantyData).filter(va => va.chasis_number === customerdata.chasis_number)?.filter( obj => obj.item === 'Battery')?.length === 0  ? <MenuItem value="Battery">Battery</MenuItem> : null}
    {(state.warrantyData).filter(va => va.chasis_number === customerdata.chasis_number)?.filter( obj => obj.item === 'Charger')?.length === 0 ? <MenuItem value="Charger">Charger</MenuItem> : null}
    {(state.warrantyData).filter(va => va.chasis_number === customerdata.chasis_number)?.filter( obj => obj.item === 'Converter')?.length === 0 ? <MenuItem value="Converter">Converter</MenuItem> : null} */}
  </Select>
</FormControl>
                </Grid>
                <Grid item xs={2}>
                <Typography >Item No *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.item_no}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Item Brand *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.item_brand}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Still Warranty *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                type="date"
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata?.still_warranty}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Capacity *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.capacity}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Issue *</Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.issue}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        issue: e.target.value,
                      })
                    }
  >
    <MenuItem value="Not Working">Not Working</MenuItem>
    <MenuItem value="Run Very Slow">Run Very Slow</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>
                </Grid>
                {customerdata?.issue === 'Other' && 
                <>
                <Grid item xs={2}>
                <Typography >Other *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.others}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        others: e.target.value,
                      })
                    }
              />
                </Grid>
                </>
                }
        </Grid>  
        <Divider sx={{mb: 2}}/>    
            <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                <Typography variant="h6">Remarks *</Typography>
                <TextField
                sx={{mt: 2}}
                rows={4}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.remarks}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        remarks: e.target.value
                      })
                    }
              />
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6">Attach Proof *</Typography>
            <CloudUpload setLoader={setLoader} file={customerdata.file_url} setFile={setFile}/>
                </Grid>
            </Grid>
            <Divider sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Claim Warranty</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Addwarranty;