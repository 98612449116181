import React, { useEffect, useState } from 'react';
import './Sidebar.css'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {Home} from '@mui/icons-material';
import classNames from 'classnames';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Sidebar = ({show, setShow}) => {

  
  const user = JSON.parse(localStorage.getItem('user'));

  const module = user?.modules;

const [selectmenu, setSelectmenu] = useState('')

const side_menus = [
        {
        menuName: 'Dashboard',
        icon: HomeOutlinedIcon,
        route: '/'
        },
        {
            menuName: 'Enquiries',
            icon: PeopleAltOutlinedIcon,
            route: '/enquiry'
        },
        {
          menuName: 'Quotation',
          icon: ArticleOutlinedIcon,
          route: '/quote'
      },
        {
            menuName: 'Customers',
            icon: ShoppingCartOutlinedIcon,
            route: '/customer'
        },
        {
          menuName: 'Inventory',
          icon: Inventory2OutlinedIcon,
          route: '/inventory'
          },
          {
              menuName: 'Purchases',
              icon: InventoryOutlinedIcon,
              route: '/purchase'
          },
          {
            menuName: 'Delivery Challan',
            icon: ArticleOutlinedIcon,
            route: '/deliverychallan'
            },
            {
                menuName: 'User Management',
                icon: SettingsOutlinedIcon,
                route: '/usermanagement'
            },
            {
              menuName: 'Support',
              icon: SupportAgentIcon,
              route: '/support'
          },
          //   {
          //     menuName: 'Report',
          //     icon: AssessmentOutlinedIcon,
          //     route: '/report'
          // },
]

const handleMenu = (val) =>{
    setSelectmenu(val);
}

useEffect(()=>{
   const val = window.location.pathname;
   const pathArray = val.split('/');
   console.log(`/${pathArray[1]}`);
   for(let i=0; i<side_menus.length; i++){
    if(`/${pathArray[1]}` === side_menus[i].route){
        setSelectmenu(side_menus[i].menuName)
    }
   }
}, [])

  return (
    <div className='sidebar' style={{left : show ? 0 : '-250px'}}>
        <Box>
            <div>
                {
                    side_menus.map((val, index)=>{
                        return (
                          module ? (module?.some(obj => obj.name === val.menuName && obj.checked) && <Link key={index} to={val.route} onClick={()=> handleMenu(val.menuName)} style={{textDecoration:'none'}}>
                            <Box className={classNames("sidemenu", `${selectmenu === val.menuName && "active"}`)} sx={{ py: 2, px: 2 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <val.icon sx={{ marginRight: 1, color: "#000" }} />
                                <Typography sx={{ color: "#000", fontSize: 14 }}>
                                  {val.menuName}
                                </Typography>
                              </div>
                            </Box>
                          </Link>
                          )
                          :
                          <Link key={index} to={val.route} onClick={()=> handleMenu(val.menuName)} style={{textDecoration:'none'}}>
                            <Box className={classNames("sidemenu", `${selectmenu === val.menuName && "active"}`)} sx={{ py: 2, px: 2 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <val.icon sx={{ marginRight: 1, color: "#000" }} />
                                <Typography sx={{ color: "#000", fontSize: 14 }}>
                                  {val.menuName}
                                </Typography>
                              </div>
                            </Box>
                          </Link>
                        );
                    })
                }
                {/* <Link to={'/'}>
                <Box className='sidemenu' sx={{py: 2, px: 3}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <Home sx={{marginRight: 1, color:'#fff'}}/>
                    <Typography sx={{color:'#fff'}}>Home</Typography> 
                    </div>
                </Box>
                </Link>
                <Link to={'/menu'}>
                <Box className='sidemenu' sx={{py: 2, px: 3}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <Home sx={{marginRight: 1, color:'#fff'}}/>
                    <Typography sx={{color:'#fff'}}>Menu</Typography> 
                    </div>
                </Box>
                </Link> */}
            </div>
        </Box>
    </div>
  )
}

export default Sidebar