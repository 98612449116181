import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));

const createPurchase = async (data) => {
  const response = await axios.post(API_URL + "api/purchase", { ...data}, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const getPurchase = async () => {
  const response = await axios.get(API_URL + `api/purchase/dealer/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const getPurchasebyID = async (p_id) => {
  const response = await axios.get(API_URL + `api/purchase/pid/${p_id}`, { headers: authHeader() });
  return response.data;
};

const updatePurchasebyID = async (p_id, formData) => {
  const response = await axios.put(API_URL + `api/purchase/pid/${p_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const purchaseService = {
  createPurchase,
  getPurchase,
  getPurchasebyID,
  updatePurchasebyID
};

export default purchaseService