import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import BasicTabs from '../../components/tabs/Tabs';

const columns = [
    { id: 'purchaseno', label: 'Purchase No', minWidth: 150, align:'start' },
    { id: 'purchaseby', label: 'Purchase By', minWidth: 150, align:'start' },
    { id: 'purchasedate', label: 'Purchase Date', minWidth: 150, align:'center' },
    { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
    { id: 'status', label: 'Status', minWidth: 150, align:'center' },
    // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];
  
  const rows = [
    {purchaseno: '01', purchaseby: 'Cow', purchasedate: 'url', qty: 'Coimbatore', status:'Active'},
    {purchaseno: '01', purchaseby: 'Cow', purchasedate: 'url', qty: 'Coimbatore', status:'Active'}
  ];

const Orders = ({setLoader, show}) => {

  const animal = useSelector((state) => state.category);

  console.log(animal);

  const dispatch = useDispatch();

  // useEffect(()=>{
  //   setLoader(true)
  //     dispatch(getcategory())
  //     .then(() => {
  //       setLoader(false);
  //     })
  //     .catch(()=>{
  //       setLoader(false);
  //     })
  // }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5'>
             Received Orders
            </Typography>
          </Grid>
          {/* <Grid item xs={4} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Dealer"/>
            <Adddealer/>
          </Box>
          </Grid> */}
        </Grid>
      </Box>
      <Box>
        <BasicTabs columns={columns} rows={rows}/>
      </Box>
    </div>
  );
}

export default Orders;