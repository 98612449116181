import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import no_data from '../../assets/images/no_data.png';


export default function CustomTable4({columns, rows, viewData}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowsUp = rows.map(va => {
    return{
      ...va,
      created_at: `${(new Date(va.created_at)).getDate()} / ${(new Date(va.created_at)).getMonth()+1} / ${(new Date(va.created_at)).getFullYear()}`,
      status:  !va.warranty_created ? 'Not Created' : !va.claim_verification ? "Verify Pending" : !va.in_transit ? "Waiting Transit" : !va.inspection ? "Inspection Pending" : !va.under_service_replace ? "Waiting S/R " : !va.returning ? "Waiting Dispatched" : !va.out_for_delivery ? "Not Delivered" : "Received" 
    }
  })

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%'}}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight:'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {
              rows.length === 0 ?
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                <img src={no_data} alt='no_data'></img>
                </TableCell>
              </TableRow>
              :
              <>
            {rowsUp
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns?.map((column, index) => {
                      const value = row[column.id];
                      return (
                        <>
                        {column.id === 'warranty_id' ? 
                        <TableCell key={index} align={column.align}>
                        <Link to={`warrantydetail/${value}`} state={{viewData: viewData}}>
                          {value}
                        </Link>
                        </TableCell>
                        :
                        <TableCell key={index} align={column.align}>
                          {value}
                            {column.id === "action" && 
                                    column.actionType.map((a)=>{
                                        return (
                                            <>
                                    {a.edit && (
                                      <Link
                                      >
                                        <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
                                      </Link>
                                    )}
                                     {a.delete && (
                                      <Link
                                      >
                                        <Button
                                          sx={{
                                            background: "#dc0707",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
                                      </Link>
                                    )}
                                            </>
                                        )
                                    })
                            }
                        </TableCell>
                    }
                        </>
                      );
                    })}
                  </TableRow>
                );
              })}
              </>
          }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}