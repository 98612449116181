import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomTable from "../table/Table";
import "./Tabs.css";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  colors,
} from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns = [
  { id: "vehicle_img", label: "Vehicle Name", minWidth: 150, align: "start" },
  { id: "product_name", label: "Name", minWidth: 150, align: "start" },
  { id: "item_type", label: "Type", minWidth: 150, align: "center" },
  { id: "variant_id", label: "Variant", minWidth: 150, align: "center" },
  { id: "hsn_no", label: "HSN Code", minWidth: 150, align: "center" },
  { id: "price", label: "Price", minWidth: 150, align: "center" },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "end",
    actionType: [{ edit: true, delete: false }],
  },
];

const columns1 = [
  { id: "vehicle_id", label: "Vehicle_Name", minWidth: 150, align: "start" },
  { id: "product_name", label: "Name", minWidth: 150, align: "start" },
  { id: "company", label: "Company", minWidth: 150, align: "center" },
  { id: "variant_id", label: "Variant", minWidth: 150, align: "center" },
  { id: "hsn_no", label: "HSN Code", minWidth: 150, align: "center" },
  { id: "price", label: "Price", minWidth: 150, align: "center" },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "end",
    actionType: [{ edit: true, delete: false }],
  },
];

export default function BasicTabs({
  value,
  setValue,
  vehicleApiData,
  spareApiData,
  accessoriesApiData,
  itemlist,
  vehicledata,
  cartcount, 
  setCartcount,
  setVehicledata,
  distab,
  setDistab
}) {

  const userData = JSON.parse(localStorage.getItem('user'));

  const rows2 = spareApiData?.map((va)=>{
    return{...va, vehicle_img: va?.vehicle?.product_img, vehicle_name: va?.vehicle?.product_name
    }
  });

  const rows3 = accessoriesApiData?.map((va)=>{
    return{...va, vehicle_img: va?.vehicle?.product_img, vehicle_name: va?.vehicle?.product_name
    }
  });

  const dealerState =  JSON.parse(userData.billing_address).state; 

  console.log(dealerState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 0){
      setVehicledata({...vehicledata, product_type: "Vehicle"})
    }
    if(newValue === 1){
      setVehicledata({...vehicledata, product_type: "Spare"})
    }
    if(newValue === 2){
      setVehicledata({...vehicledata, product_type: "Accessory"})
    }
  };

  const productList = {
    product_img:"",
    product_name:"",
    selecVariant:"",
    selecColor:"",
    ex_price:"",
    dealer_price:""
  }

  const [list, setList] = React.useState([]);


  const rows1 = vehicleApiData?.map((va) => {
    return {
      product_img: va.product_img,
      product_name: va.product_name,
      type: va.product_type,
      variant: va?.variant?.map((obj) => {
        return { ...obj, color_details: JSON.parse(obj.color_details), price_groups: JSON.parse(obj.price_groups) };
      }),
      novariants: va?.variant?.length,
      availability: "Available",
    };
  });

  console.log(list, rows1, dealerState);

  React.useEffect(()=>{
    setList(rows1.map((va, index)=>{
            return {...productList , product_img: va?.product_img, product_name:va?.product_name, selecVariant:va?.variant[0]?.variant_name, selecColor:va?.variant[0]?.color_details[0]?.code, variant:va?.variant, ex_price: va?.variant[0]?.price_groups?.filter(obj => obj?.state === dealerState)[0]?.ex_showroom, dealer_price: va?.variant[0]?.price_groups?.filter(obj => obj?.state === dealerState)[0]?.dealer}
          }) )
  }, [vehicleApiData])


  const handleVariant = (e, name) =>{
    const newValue = e.target.value;
    setList(prevItems => {
      return prevItems.map(item => {
        if (item.product_name === name) {
          return { ...item, selecVariant: newValue, selecColor: item?.variant?.filter(obj => obj?.variant_name === e.target.value)[0]?.color_details[0]?.code, dealer_price: item?.variant?.filter(obj => obj?.variant_name === e.target.value)[0]?.price_groups?.filter(obj => obj?.state === dealerState)[0]?.dealer};
        }
        return item;
      });
    });
  }

  const handleColors = (e, name) =>{
    const newValue = e.target.value;
    setList(prevItems => {
      return prevItems.map(item => {
        if (item.product_name === name) {
          return { ...item, selecColor: newValue};
        }
        return item;
      });
    });
}

const handleCartitems = (ec) =>{
    console.log(ec);
    if(ec?.product_type === "Spare"){
      setDistab("Spare")
      vehicledata.item_list.push({...itemlist, 
        ...ec,
        vehicle_id: ec.vehicle_id,
        variant_id: ec.variant_id,
        product_img: ec.product_img,
        vechile_name: ec.vehicle_name,
        variant_name: ec.variant.variant_name,
        ordered_color: "",
        approved_color: "",
        ordered_qty: 1,
        approved_qty: null,
        dealer_price: ec.price, 
        total_price: ec.price,
        cart_id: cartcount+1})
        setCartcount(cartcount+1)
    }
    if(ec?.product_type === "Accessory"){
      setDistab("Accessory")
      vehicledata.item_list.push({...itemlist, 
        ...ec,
        vehicle_id: ec.vehicle_id,
        variant_id: ec.variant_id,
        product_img: ec.product_img,
        vechile_name: ec.vehicle_name,
        variant_name: ec.variant.variant_name,
        ordered_color: "",
        approved_color: "",
        ordered_qty: 1,
        approved_qty: null,
        dealer_price: ec.price, 
        total_price: ec.price,
        cart_id: cartcount+1})
        setCartcount(cartcount+1)
    }
    if(vehicledata?.product_type === "Vehicle")
      {
      setDistab("Vehicle")
      vehicledata.item_list.push({...itemlist, 
        vehicle_id: ec.variant[0].vehicle_id,
        variant_id: ec.variant[0].id,
        product_img: ec.product_img,
        vechile_name: ec.product_name,
        variant_name: ec.selecVariant,
        ordered_color: ec.selecColor,
        approved_color: "",
        ordered_qty: 1,
        approved_qty: null,
        dealer_price: ec.dealer_price,
        total_price: ec.dealer_price,
        cart_id: cartcount+1})   
        setCartcount(cartcount+1)
    }
};

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Vehicle Model" {...a11yProps(0)} disabled={distab === null ? false : distab !== "Vehicle" ? true : false} sx={{fontWeight:'bold'}}/>
          <Tab label="Spare Parts" {...a11yProps(1)} disabled={distab === null ? false : distab !== "Spare" ? true : false} sx={{fontWeight:'bold'}}/>
          <Tab label="Accessory" {...a11yProps(2)} disabled={distab === null ? false : distab !== "Accessory" ? true : false} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {list.map((ec, index) => {
          return (
            <Card
              key={index}
              sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2 }}
            >
              <Grid container rowSpacing={2} sx={{ p: 2 }}>
                <Grid item xs={2}>
                  <img
                    src={ec.product_img}
                    alt="img"
                    width={60}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {ec.product_name}
                  </Typography>
                  <FormControl
                  onChange={(e) => handleVariant(e, ec.product_name)}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={ec.selecVariant}
                    >
                      {ec?.variant?.map((va, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={va.variant_name}
                            control={<Radio />}
                            label={va.variant_name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <Box component="div">
                    <FormControl
                    onChange={(e) => handleColors(e, ec.product_name)}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={ec.selecColor}
                      >
                        {(ec?.variant.filter(obj => obj.variant_name === ec.selecVariant))[0]?.color_details?.map((ka, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={ka.code}
                              control={<Radio sx={{ bgColor: "#000" }} />}
                              label={
                                <p
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "30px",
                                    background: `${ka.code}`,
                                    margin: 0,
                                  }}
                                ></p>
                              }
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "end" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    ₹ {ec.dealer_price}
                  </Typography>
                  <Button variant="contained" sx={{mt: 5}} onClick={() => handleCartitems(ec)}>Add to cart</Button>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {rows2.map((ec, index) => {
          return (
            <Card
              key={index}
              sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2 }}
            >
              <Grid container rowSpacing={2} sx={{ p: 2 }}>
                <Grid item xs={2}>
                  <img
                    src={ec.product_img}
                    alt="img"
                    width={60}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {ec.product_name} - {ec.item_type}
                  </Typography>
                  <Typography sx={{my: 1}}>
                    {ec?.vehicle?.product_name} - {ec?.variant?.variant_name}
                  </Typography>
                  <Typography sx={{my: 1}}>
                    HSN No : {ec?.vehicle?.hsn_no}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "end" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    ₹ {ec.price}
                  </Typography>
                  <Button variant="contained" sx={{mt: 2}} onClick={() => handleCartitems(ec)}>Add to cart</Button>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {rows3.map((ec, index) => {
          return (
            <Card
              key={index}
              sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2 }}
            >
              <Grid container rowSpacing={2} sx={{ p: 2 }}>
                <Grid item xs={2}>
                  <img
                    src={ec.product_img}
                    alt="img"
                    width={60}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {ec.product_name} - {ec.item_type}
                  </Typography>
                  <Typography sx={{my: 1}}>
                    {ec?.vehicle?.product_name} - {ec?.variant?.variant_name}
                  </Typography>
                  <Typography sx={{my: 1}}>
                    HSN No : {ec?.vehicle?.hsn_no}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "end" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    ₹ {ec.price}
                  </Typography>
                  <Button variant="contained" sx={{mt: 2}} onClick={() => handleCartitems(ec)}>Add to cart</Button>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </CustomTabPanel>
    </Box>
  );
}
