import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, updatecustomer } from "../../slices/customer";


const states =
[
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
]

const Updatecustomer = ({ setLoader, show }) => {

  const params = useParams();

  let { state } = useLocation();

  const [panfile, setPanFile] = useState(null);

  const [aadharfile, setAadharFile] = useState(null);

  const [licenccefile, setLicenceFile] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));

  const [customerdata, setCustomerdata] = useState({
      "dealer_id": user.dealer_id,
      "customer_type": "Business",
      "title": "Mr",
      "first_name": "",
      "last_name": "",
      "email": "",
      "customer_phone_work": "",
      "customer_phone_mobile": "",
      "billing_address": "",
      "shipping_address": "",
      "pan_no": "",
      "aadhar_no": "",
      "licence_no": "",
      "pan_doc": panfile,
      "aadhar_doc": aadharfile,
      "licence_doc": licenccefile,
  })

  const [billing, setBilling] = useState({
    country:"India",
    state:"",
    address:"",
    city:"",
    pincode:""
  })

  const [shipping, setShipping] = useState({
    country:"India",
    state:"",
    address:"",
    city:"",
    pincode:""
  })

  useEffect(()=>{
    if(panfile){
      setCustomerdata({...customerdata, pan_doc: panfile})
    }
    if(aadharfile){
      setCustomerdata({...customerdata, aadhar_doc: aadharfile})
    }
    if(licenccefile){
      setCustomerdata({...customerdata, licence_doc: licenccefile})
    }
  },[panfile, aadharfile, licenccefile])

  const copyAddress =()=>{
    setShipping(billing)
  }



  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {...customerdata, billing_address: JSON.stringify(billing), shipping_address:JSON.stringify(shipping)};
    const c_id = params.cusId
    setLoader(true)
    dispatch(updatecustomer({c_id, formData}))
    .then(() => {
      navigate("/customer");
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  useEffect(()=>{
    if(state?.viewData){
      setCustomerdata({
        ...state.viewData
      })
      setBilling({...state.viewData.billing_address})
      setShipping({...state.viewData.shipping_address})
    }
  }, [state])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>Update Customer</Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={4}>
                <Typography >Customer Type *</Typography>
                </Grid>
                <Grid item xs={8}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={customerdata.customer_type}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        customer_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Business"
                    control={<Radio />}
                    label="Business"
                  />
                  <FormControlLabel
                    value="Individual Customer"
                    control={<Radio />}
                    label="Individual Customer"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={4}>
                <Typography >Customer Name *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <FormControl size="small"  sx={{ minWidth: 80 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={customerdata.title}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        title: e.target.value,
                      })
                    }
                  >
                    <MenuItem selected value="Mr">
                      Mr
                    </MenuItem>
                    <MenuItem value="Miss">Miss</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </Select>
                </FormControl>{" "}
                <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="First Name"
                  variant="outlined"
                  value={customerdata.first_name}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        first_name: e.target.value,
                      })
                    }
                />
                <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="Last Name"
                  variant="outlined"
                  value={customerdata.last_name}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        last_name: e.target.value,
                      })
                    }
                />
              </Box>
                </Grid>
                <Grid item xs={4}>
                <Typography >Customer Email *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="test@gmail.com"
                variant="outlined"
                value={customerdata.email}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        email: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Customer Phone *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Contact Number"
                variant="outlined"
                value={customerdata.customer_phone_mobile}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        customer_phone_mobile: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Customer Alt Phone *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Contact Number"
                variant="outlined"
                value={customerdata.customer_phone_work}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        customer_phone_work: e.target.value,
                      })
                    }
              />
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                  <Grid container spacing={2} sx={{ alignItems:'center'}}>
                  <Grid item xs={12}>
                <Typography variant="h6">Billing Address</Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography >Country/Region *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={billing.country}
                onChange={(e) =>
                      setBilling({
                        ...billing,
                        country: e.target.value,
                      })
                    }
                InputProps={{
            readOnly: true,
          }}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >State *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Autocomplete size="small"
  disablePortal
  id="combo-box-demo"
  options={states}
  value={billing.state}
                onChange={(e, value) =>
                      setBilling({
                        ...billing,
                        state: value,
                      })
                    }
  renderInput={(params) => <TextField placeholder="Select State" {...params}  />}
/>
                </Grid>
                <Grid item xs={4}>
                <Typography >Address *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={billing.address}
                onChange={(e) =>
                      setBilling({
                        ...billing,
                        address: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >City *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={billing.city}
                onChange={(e) =>
                      setBilling({
                        ...billing,
                        city: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Pincode *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={billing.pincode}
                onChange={(e) =>
                      setBilling({
                        ...billing,
                        pincode: e.target.value,
                      })
                    }
              />
                </Grid>
                  </Grid>
            </Grid>
            <Grid item xs={6}>
                  <Grid container spacing={2} sx={{ alignItems:'center'}}>
                  <Grid item xs={12}>
                <Typography variant="h6">Shipping Address, <span onClick={copyAddress} style={{fontSize: '14px', color:'red', cursor:'pointer'}}>Copy Billing address here</span></Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography >Country/Region *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={shipping.country}
                onChange={(e) =>
                      setShipping({
                        ...shipping,
                        country: e.target.value,
                      })
                    }
                InputProps={{
            readOnly: true,
          }}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >State *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Autocomplete size="small"
  disablePortal
  id="combo-box-demo"
  options={states}
  value={shipping.state}
                onChange={(e, value) =>
                      setShipping({
                        ...shipping,
                        state: value,
                      })
                    }
  renderInput={(params) => <TextField placeholder="Select State" {...params}  />}
/>
                </Grid>
                <Grid item xs={4}>
                <Typography >Address *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={shipping.address}
                onChange={(e) =>
                      setShipping({
                        ...shipping,
                        address: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >City *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={shipping.city}
                onChange={(e) =>
                      setShipping({
                        ...shipping,
                        city: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Pincode *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={shipping.pincode}
                onChange={(e) =>
                      setShipping({
                        ...shipping,
                        pincode: e.target.value,
                      })
                    }
              />
                </Grid>
                  </Grid>
            </Grid>
            </Grid>

            <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                  <Grid container spacing={2} sx={{ alignItems:'center'}}>
                  <Grid item xs={12}>
                <Typography variant="h6">Documents</Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography >PAN No *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.pan_no}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        pan_no: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Upload Proof *</Typography>
                </Grid>
                <Grid item xs={8}>
                <CloudUpload setLoader={setLoader} file={customerdata.pan_doc} setFile={setPanFile}/>
                </Grid>
                <Grid item xs={4}>
                <Typography >Aadhar No *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.aadhar_no}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        aadhar_no: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Upload Proof *</Typography>
                </Grid>
                <Grid item xs={8}>
                <CloudUpload setLoader={setLoader} file={customerdata.aadhar_doc} setFile={setAadharFile}/>
                </Grid>
                <Grid item xs={4}>
                <Typography >Licence No *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.licence_no}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        licence_no: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Upload Proof *</Typography>
                </Grid>
                <Grid item xs={8}>
                <CloudUpload setLoader={setLoader} file={customerdata.licence_doc} setFile={setLicenceFile}/>
                </Grid>
                  </Grid>
            </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Update Customer</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Updatecustomer;