import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Button, Card, CardContent, Divider, Grid, Modal, TextField, colors } from '@mui/material';
import SearchButton from '../search/Search';
import { useDispatch } from 'react-redux';
import { createserviceprice, deleteserviceprice, updateserviceprice } from '../../slices/inventory';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import no_data from '../../assets/images/no_data.png';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns = [
  { id: 'vehicle_id', label: 'Vehicle_Name', minWidth: 150, align:'start' },
  { id: 'product_name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'item_type', label: 'Type', minWidth: 150, align:'center' },
  { id: 'variant_id', label: 'Variant', minWidth: 150, align:'center' },
  { id: 'hsn_no', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'price', label: 'Price', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns1 = [
  { id: 'vehicle_id', label: 'Vehicle_Name', minWidth: 150, align:'start' },
  { id: 'product_name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'company', label: 'Company', minWidth: 150, align:'center' },
  { id: 'variant_id', label: 'Variant', minWidth: 150, align:'center' },
  { id: 'hsn_no', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'price', label: 'Price', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

export default function BasicTabs({ value, setValue, setLoader, vehicleApiData, spareApiData, accessoriesApiData, vehicleStockData, spareStockData, accessoriesStockData, servicePriceData}) {

  const [open, setOpen] = React.useState(false);
  const [editser, setEditser] = React.useState(false);

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('user'));

  const initState = {
      service_name:"",
      created_by: user.first_name,
      price:""
  }

  const [servicePrice, setServicePrice] = React.useState(initState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFieldChange = (e, name) => {
    setServicePrice({
      ...servicePrice,
      [name]: e.target.value,
    })
  }

  const handleServiceEdit = (ec) => {
      setOpen(true);
      setServicePrice(ec);
      setEditser(true);
  }

  const mergedArray = vehicleStockData?.reduce((acc, curr) => {
    const existingIndex = acc.findIndex(item => item.vehicle.id === curr.vehicle.id && item.variant.id === curr.variant.id);
    if (existingIndex !== -1) {
      acc[existingIndex].stocks.push(curr.stock);
    } else {
      acc.push({ ...curr, stocks: [curr.stock] });
    }
    return acc;
  }, []);


  const rows1 = mergedArray?.map((va)=>{
    return{
      product_img: va?.vehicle?.product_img,
      product_name: va?.vehicle?.product_name,
      variant_name: va?.variant?.variant_name,
      colours: va?.variant?.color_details && JSON.parse(va?.variant?.color_details)?.map(obj => obj?.code),
      stocks: va?.stocks?.length,
      registration_type: va?.vehicle?.registration,
      subsidy: va?.variant?.subsidy
    }
  });

  console.log(spareStockData);

  const mergedArray1 = spareStockData?.reduce((acc, curr) => {
    const existingIndex = acc.findIndex(item => item.vehicle.id === curr.vehicle.id && item.variant.id === curr.variant.id);
    if (existingIndex !== -1) {
      acc[existingIndex].stocks.push(curr.stock);
    } else {
      acc.push({ ...curr, stocks: [curr.stock] });
    }
    return acc;
  }, []);


  const rows2 = mergedArray1.map((va)=>{
    return{
      product_img: va?.spare?.product_img,
      product_name: va?.spare?.product_name,
      item_type: va?.spare?.item_type,
      hsn_no: va?.spare?.hsn_no,
      stocks: va?.stocks?.length,
    }
  });

  const mergedArray3 = accessoriesStockData?.reduce((acc, curr) => {
    const existingIndex = acc.findIndex(item => item.vehicle.id === curr.vehicle.id && item.variant.id === curr.variant.id);
    if (existingIndex !== -1) {
      acc[existingIndex].stocks.push(curr.stock);
    } else {
      acc.push({ ...curr, stocks: [curr.stock] });
    }
    return acc;
  }, []);


  const rows3 = mergedArray3.map((va)=>{
    return{
      product_img: va?.accessory?.product_img,
      product_name: va?.accessory?.product_name,
      company: va?.accessory?.company,
      hsn_no: va?.accessory?.hsn_no,
      stocks: va?.stocks?.length,
    }
  });

  const serviceUp = servicePriceData.map(va => {
    return{
      ...va,
      created_at: `${(new Date(va.created_at)).getDate()} / ${(new Date(va.created_at)).getMonth()+1} / ${(new Date(va.created_at)).getFullYear()}`,
    }
  })

  const handleService = (e) =>{
    e.preventDefault();
    setLoader(true)
    if(editser){
      const formData = servicePrice;
      const ser_id = servicePrice.id;
      dispatch(updateserviceprice({ser_id, formData}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        setEditser(false);
        setServicePrice(initState);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
    else{
      dispatch(createserviceprice({...servicePrice}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        setServicePrice(initState)
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  }

  const handleServiceDelete = (ec) => {
    const ser_id = ec.id;
    dispatch(deleteserviceprice({ser_id}))
    .then(() => {
      setLoader(false);
      setOpen(false);
      setServicePrice(initState)
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle Model" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
          <Tab label="Spare Parts" {...a11yProps(1)} sx={{fontWeight:'bold'}}/>
          <Tab label="Accessory" {...a11yProps(2)} sx={{fontWeight:'bold'}}/>
          <Tab label="Service Price" {...a11yProps(3)} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      {rows1.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        rows1.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={1}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Variant Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.variant_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Colours</Typography>
                  <Box sx={{display:'flex', gap:'8px'}}>
                  {ec?.colours?.map((ka, index)=>{
                    return(
                      <p key={index} style={{width:'25px', height:'25px', borderRadius:'30px', background:`${ka}`, margin: 0}}></p>
                    )
                  })}
                  </Box>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Stocks</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.stocks}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Registration Type</Typography>
                  <Typography sx={{color: '#2e7d32', fontWeight:'bold'}}>{ec.registration_type}</Typography>
              </Grid>
              <Grid item xs={1}>
                  <Typography variant='body1'>Subsidy</Typography>
                  <Typography sx={{color: '#2e7d32', fontWeight:'bold'}}>{ec.subsidy}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {rows2.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        rows2.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={3}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Product Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Item type</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.item_type}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>HSN No</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.hsn_no}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Stocks</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.stocks}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {rows3.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        rows3.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={3}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Product Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.company}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>HSN No</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.hsn_no}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Stocks</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.stocks}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }        </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
        {/* <SearchButton placeholder="Search Service No"/> */}
        </Grid>
        <Grid item xs={6} sx={{textAlign:'end'}}>
        <Button variant='contained' onClick={()=>setOpen(true)} size='large'>+ New Servcie Price</Button>
        <Modal
                        open={open}
                        onClose={()=>{
                          setEditser(false);
                          setServicePrice(initState);
                          setOpen(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                        <Typography variant="h6">{editser ? "Edit Service Price": "Add Service Price"}</Typography>
                        <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Grid container spacing={2}>
                          <Grid item xs={12}>
                <Typography sx={{mb:0.5}}>Created By *</Typography>
                <TextField
                size="large"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                inputProps={{
                  readOnly: true
                }}
                value={servicePrice.created_by}
                onChange={(e) => handleFieldChange(e, 'created_by')}
              />
                </Grid>
                <Grid item xs={12}>
                <Typography sx={{mb:0.5}}>Service Name *</Typography>
                <TextField
                size="large"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={servicePrice.service_name}
                onChange={(e) => handleFieldChange(e, 'service_name')}
              />
                </Grid>
                <Grid item xs={12}>
                <Typography sx={{mb:0.5}}>Price *</Typography>
                <TextField
                size="large"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={servicePrice.price}
                onChange={(e) => handleFieldChange(e, 'price')}
              />
                </Grid>
                          </Grid>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                            <Button variant="outlined"
                            onClick={()=>{
                          setEditser(false);
                          setServicePrice(initState);
                          setOpen(false);
                        }}
                            sx={{mr: 1}}
                            >
                                Cancel
                              </Button>
                              <Button variant="contained" onClick={handleService}>
                                {editser ? "Update":"Submit"}
                              </Button>
                            </Box>
                        </Box>
                      </Modal>  
        
        </Grid>
        {serviceUp.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        <Grid item xs={12}>
        {
        serviceUp.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={2.5}>
                  <Typography variant='body1'>Service Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.service_name}</Typography>
              </Grid>
              <Grid item xs={2.5}>
                  <Typography variant='body1'>Created By</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.created_by}</Typography>
              </Grid>
              <Grid item xs={2.5}>
                  <Typography variant='body1'>Created On</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.created_at}</Typography>
              </Grid>
              <Grid item xs={2.5}>
                  <Typography variant='body1'>Price</Typography>
                  <Typography sx={{fontWeight:'bold'}}>₹ {ec.price}</Typography>
              </Grid>
              <Grid item xs={2}>
              <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={()=>handleServiceEdit(ec)}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
                                        <Button
                                          sx={{
                                            background: "#dc0707",
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={()=> handleServiceDelete(ec)}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
        </Grid>
        }
      </Grid>
      </CustomTabPanel>
    </Box>
  );
}