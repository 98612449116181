import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import inventoryService from "../services/inventory.service";

export const createproductstock = createAsyncThunk(
  "api/createproductstock",
  async (stateData, thunkAPI) => {
    try {
      const data = await inventoryService.createProductStock(stateData);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createsparestock = createAsyncThunk(
  "api/createsparestock",
  async (stateData, thunkAPI) => {
    try {
      const data = await inventoryService.createSpareStock(stateData);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createaccessorystock = createAsyncThunk(
  "api/createaccessorystock",
  async (stateData, thunkAPI) => {
    try {
      const data = await inventoryService.createAccessoryStock(stateData);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getproductstock = createAsyncThunk(
  "api/getproductstock",
  async (thunkAPI) => {
    try {
      const data = await inventoryService.getProductStock();
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getsparestock = createAsyncThunk(
  "api/getsparestock",
  async (thunkAPI) => {
    try {
      const data = await inventoryService.getSpareStock();
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getaccessorystock = createAsyncThunk(
  "api/getaccessorystock",
  async (thunkAPI) => {
    try {
      const data = await inventoryService.getAccessoriesStock();
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteproductstock = createAsyncThunk(
  "api/deleteproductstock",
  async ({inv_id}, thunkAPI) => {
    console.log(inv_id);
    try {
      const data = await inventoryService.deleteProductStock(inv_id);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletesparestock = createAsyncThunk(
  "api/deletesparestock",
  async ({inv_id}, thunkAPI) => {
    console.log(inv_id);
    try {
      const data = await inventoryService.deleteSpareStock(inv_id);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteaccessorystock = createAsyncThunk(
  "api/deleteaccessorystock",
  async ({inv_id}, thunkAPI) => {
    console.log(inv_id);
    try {
      const data = await inventoryService.deleteAccessoryStock(inv_id);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createserviceprice = createAsyncThunk(
  "api/createserviceprice",
  async (stateData, thunkAPI) => {
    try {
      const data = await inventoryService.createServicePrice(stateData);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getserviceprice = createAsyncThunk(
  "api/getserviceprice",
  async (thunkAPI) => {
    try {
      const data = await inventoryService.getServicePriceBydealer();
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateserviceprice = createAsyncThunk(
  "api/updateserviceprice",
  async ({ser_id, formData}, thunkAPI) => {
    try {
      const data = await inventoryService.updateServicePricebyId(ser_id, formData);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteserviceprice = createAsyncThunk(
  "api/deleteserviceprice",
  async ({ser_id}, thunkAPI) => {
    try {
      const data = await inventoryService.deleteServicePricebyId(ser_id);
      return { inventorydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    vehicles: [],
    spares:[],
    accessories:[],
    servicePrice:[]
}

const inventorySlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createproductstock.fulfilled]: (state, action) => {
      state.vehicles.push(action.payload.inventorydata.data)
      return state;
    },
    [createproductstock.rejected]: (state, action) => {
      state.vehicles = action.payload.inventorydata
      return state;
    },
    [createsparestock.fulfilled]: (state, action) => {
      state.spares.push(action.payload.inventorydata.data)
      return state;
    },
    [createsparestock.rejected]: (state, action) => {
      state.spares = action.payload.inventorydata
      return state;
    },
    [createaccessorystock.fulfilled]: (state, action) => {
      state.accessories.push(action.payload.inventorydata.data)
      return state;
    },
    [createaccessorystock.rejected]: (state, action) => {
      state.accessories = action.payload.inventorydata
      return state;
    },
    [getproductstock.fulfilled]: (state, action) => {
      state.vehicles = action.payload.inventorydata.data
      return state;
    },
    [getproductstock.rejected]: (state, action) => {
      state.vehicles = action.payload.inventorydata
      return state;
    },
    [getsparestock.fulfilled]: (state, action) => {
      state.spares = action.payload.inventorydata.data
      return state;
    },
    [getsparestock.rejected]: (state, action) => {
      state.spares = action.payload.inventorydata
      return state;
    },
    [getaccessorystock.fulfilled]: (state, action) => {
      state.accessories = action.payload.inventorydata.data
      return state;
    },
    [getaccessorystock.rejected]: (state, action) => {
      state.accessories = action.payload.inventorydata
      return state;
    },
    [deleteproductstock.fulfilled]: (state, action) => {
      const {
        arg: { inv_id },
      } = action.meta;
      if (inv_id) {
        state.vehicles = state.vehicles.filter((item) => item.id !== inv_id);
      };
      state.message = action.payload.inventorydata.message
    },
    [deleteproductstock.rejected]: (state, action) => {
      state.message = action.payload.inventorydata.message
    },
    [deletesparestock.fulfilled]: (state, action) => {
      const {
        arg: { inv_id },
      } = action.meta;
      if (inv_id) {
        state.spares = state.spares.filter((item) => item.id !== inv_id);
      };
      state.message = action.payload.inventorydata.message
    },
    [deleteaccessorystock.fulfilled]: (state, action) => {
      const {
        arg: { inv_id },
      } = action.meta;
      if (inv_id) {
        state.accessories = state.accessories.filter((item) => item.id !== inv_id);
      };
      state.message = action.payload.inventorydata.message
    },
    [createserviceprice.fulfilled]: (state, action) => {
      state.servicePrice.push(action.payload.inventorydata.data)
      state.message = "Create Service Price Successfully"
      return state;
    },
    [getserviceprice.fulfilled]: (state, action) => {
    state.servicePrice = action.payload.inventorydata.data
    state.message = null
    return state;
  },
  [updateserviceprice.fulfilled]: (state, action) => {
    const {
      arg: { ser_id },
    } = action.meta;
    if (ser_id) {
      state.servicePrice = state.servicePrice.map((item) =>
        item.id === ser_id ? action.payload.inventorydata.data : item
      );
    };
    state.message = "Update Service Price Successfully"
  },
  [deleteserviceprice.fulfilled]: (state, action) => {
    const {
      arg: { ser_id },
    } = action.meta;
    if (ser_id) {
      state.servicePrice = state.servicePrice.filter((item) => item.id !== ser_id);
    };
    state.message = action.payload.inventorydata.message
  },
  },
});

const { reducer } = inventorySlice;
export default reducer;