import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';

const columns = [
    { id: 'wid', label: 'Warranty ID', minWidth: 150, align:'start' },
    { id: 'dname', label: 'Dealer Name', minWidth: 150, align:'start' },
    { id: 'cname', label: 'Customer Name', minWidth: 150, align:'center' },
    { id: 'model', label: 'Model', minWidth: 150, align:'center' },
    { id: 'item', label: 'Item', minWidth: 150, align:'center' },
    { id: 'hsncode', label: 'HSN Code', minWidth: 150, align:'center' },
    { id: 'claimdate', label: 'Claim Date', minWidth: 150, align:'center' },
    // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];
  
  const rows = [
    {wid: '01', dname: 'Cow', cname: 'url', model: 'Coimbatore', item:'GSTIN0008765', hsncode:'Active', claimdate:'15/09/2023'},
    {wid: '01', dname: 'Cow', cname: 'url', model: 'Coimbatore', item:'GSTIN0008765', hsncode:'Active', claimdate:'15/09/2023'},
  ];

const Warranty = ({setLoader, show}) => {

  const animal = useSelector((state) => state.category);

  console.log(animal);

  const dispatch = useDispatch();

  // useEffect(()=>{
  //   setLoader(true)
  //     dispatch(getcategory())
  //     .then(() => {
  //       setLoader(false);
  //     })
  //     .catch(()=>{
  //       setLoader(false);
  //     })
  // }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5'>
            Received Warranty
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Here"/>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable columns={columns} rows={rows} />
      </Box>
    </div>
  );
}

export default Warranty;