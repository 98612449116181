import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import enquiryService from "../services/enquiry.service";

export const createenquiry = createAsyncThunk(
  "api/createenquiry",
  async (stateData, thunkAPI) => {
    try {
      const data = await enquiryService.createNewenquiry(stateData);
      return { enquirydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getenquiry = createAsyncThunk(
  "api/getquiry",
  async (thunkAPI) => {
    try {
      const data = await enquiryService.getEnquiry();
      return { enquirydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateenquiry = createAsyncThunk(
  "api/updateenquiry",
  async ({e_id, formData}, thunkAPI) => {
    try {
      const data = await enquiryService.updateEnquiry(e_id, formData);
      return { enquirydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteenquiry = createAsyncThunk(
  "api/deleteenquiry",
  async ({e_id}, thunkAPI) => {
    try {
      const data = await enquiryService.deleteEnquiry(e_id);
      return { enquirydata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    enquiry: []
}

const enquirySlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createenquiry.fulfilled]: (state, action) => {
      state.enquiry.push(action.payload.enquirydata.data)
      return state;
    },
    [createenquiry.rejected]: (state, action) => {
      state.enquiry = action.payload.enquirydata
      return state;
    },
    [getenquiry.fulfilled]: (state, action) => {
    state.enquiry = action.payload.enquirydata.data
    return state;
  },
  [getenquiry.rejected]: (state, action) => {
    state.enquiry = action.payload.enquirydata
    return state;
  },
  [updateenquiry.fulfilled]: (state, action) => {
    const {
      arg: { e_id },
    } = action.meta;
    if (e_id) {
      state.enquiry = state.enquiry.map((item) =>
        item.id === e_id ? action.payload.enquirydata.data : item
      );
    };
    state.message = action.payload.enquirydata.message
  },
  [updateenquiry.rejected]: (state, action) => {
    state.message = action.payload.enquirydata.message
  },
  [deleteenquiry.fulfilled]: (state, action) => {
    const {
      arg: { e_id },
    } = action.meta;
    if (e_id) {
      state.enquiry = state.enquiry.filter((item) => item.id !== e_id);
    };
    state.message = action.payload.enquirydata.message
  },
  },
});

const { reducer } = enquirySlice;
export default reducer;