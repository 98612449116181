import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import purchaseService from "../services/purchase.service";

export const createpurchase = createAsyncThunk(
  "api/createpurchase",
  async (stateData, thunkAPI) => {
    try {
      const data = await purchaseService.createPurchase(stateData);
      return { purchasedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getpurchase = createAsyncThunk(
  "api/getpurchase",
  async (thunkAPI) => {
    try {
      const data = await purchaseService.getPurchase();
      return { purchasedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getpurchasebyid = createAsyncThunk(
  "api/getpurchasebyid",
  async (p_id, thunkAPI) => {
    try {
      const data = await purchaseService.getPurchasebyID(p_id);
      return { purchasedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatepurchasebyid = createAsyncThunk(
  "api/updatepurchasebyid",
  async ({p_id, formData}, thunkAPI) => {
    try {
      const data = await purchaseService.updatePurchasebyID(p_id, formData);
      return { purchasedata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    purchases: [],
    detail: null,
    message:  null
}

const purchaseSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createpurchase.fulfilled]: (state, action) => {
      state.purchases.push(action.payload.purchasedata.data)
      state.message = "PO Created Successfully"
      return state;
    },
    [createpurchase.rejected]: (state, action) => {
      state.purchases = action.payload.purchasedata
      return state;
    },
    [getpurchase.fulfilled]: (state, action) => {
    state.purchases = action.payload.purchasedata.data
    state.message = null
    return state;
  },
  [getpurchase.rejected]: (state, action) => {
    state.purchases = action.payload.purchasedata
    return state;
  },
  [getpurchasebyid.fulfilled]: (state, action) => {
    state.detail = action.payload.purchasedata.data[0]
    return state;
  },
  [getpurchasebyid.rejected]: (state, action) => {
    state.detail = action.payload.purchasedata
    return state;
  },
  [updatepurchasebyid.fulfilled]: (state, action) => {
    const {
      arg: { p_id },
    } = action.meta;
    if (p_id) {
      state.purchases = state.purchases.map((item) =>
        item.purchase_id === p_id ? action.payload.purchasedata.data : item
      );
      state.detail = action.payload.purchasedata.data 
    };
    state.message = action.payload.purchasedata.message
  },
  [updatepurchasebyid.rejected]: (state, action) => {
    state.message = action.payload.purchasedata.message
  },
  },
});

const { reducer } = purchaseSlice;
export default reducer;