import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, getinvoicebycus, getinvoicebyid, getpaymentbycus, getpaymentbyid, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CustomTable2 from "../../components/table/paytable";
import { useReactToPrint } from 'react-to-print';
import { getchallanbyid } from "../../slices/challan";

const columns2 = [
  { id: 'payment_id', label: 'Payment ID', minWidth: 200, align:'start' },
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 150, align:'center' },
  { id: 'total', label: 'Amount', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];


const Viewchallan = ({ setLoader, show }) => {

  const componentRef = useRef();

  const params = useParams()

  const {getChallan: paymentData} = useSelector((state) => state.challan) || [];

  const user = JSON.parse(localStorage.getItem('user'));

  const dealerState =  JSON.parse(user.billing_address).state; 


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(()=>{
    setLoader(true)
    const c_id = params.challanId;
      dispatch(getchallanbyid(c_id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px", marginBottom:'150px'}}>
    <Grid container spacing={2}>
    <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5">{params.invoiceId}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={handlePrint}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}
          >
            <LocalPrintshopOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Print</Typography>
          </Grid>
    </Grid>
      <Box ref={componentRef} sx={{mt: 2, border:'1px solid #bababa', p: 3}}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
        <Grid item xs={6} sx={{display:'flex', alignItems:'center', justifyContent:'start', gap: 2}}>
                <Typography><img src={user?.logo_url} width={100}></img></Typography>
                <Box>
                <Typography variant="h6">{user.organization_name}</Typography>
                <Typography >{Object.values(JSON.parse(user?.billing_address)).join(', ')}</Typography>
                <Typography >{user.contact}</Typography>
                </Box>
            </Grid>
        <Grid item xs={6} sx={{textAlign:'end'}}>
                <Typography variant="h6">Delivery Challan</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                <Typography >Billed to</Typography>
                <Typography variant="h6">{paymentData[0]?.dealer_name}</Typography>
                <Typography variant="h6">{paymentData[0]?.challan_type}</Typography>
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'end'}}>
                <Typography >Date: {paymentData[0]?.delivery_challan_date}</Typography>
                <Typography >Reference # : {paymentData[0]?.reference}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>Items Details</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Quantity</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Rate</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {paymentData[0]?.items && JSON.parse(paymentData[0]?.items).map((va, index) => {
          return(
            <TableRow key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {va?.itemDetails}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {va?.rate}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {va?.quantity}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {va?.rate * va?.quantity}
              </TableCell>
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
                </Grid>
                <Grid item xs={12}>
                <Divider sx={{mb: 2}}/>           
                </Grid>
            <Grid item xs={6}>
            <Typography >Customer Notes</Typography>
                <Typography >{paymentData[0]?.customer_notes}</Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 2, background:'#eaeaea', textAlign:'end'}}>
            <Grid container spacing={2}>
          <Grid item xs={9} sx={{textAlign:'end'}}>
              <Typography variant="body1" sx={{mb: 1}}>Sub Total</Typography>
              <Typography  variant="body1"  sx={{mb: 1}}>Adjustment</Typography>
              <Typography  variant="h6">Total Amount</Typography>
          </Grid>
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1" sx={{mb: 1}}>₹ {paymentData[0]?.sub_total}</Typography>
              <Typography variant="body1" sx={{mb: 1}}>₹ {paymentData[0]?.adjustment}</Typography>
              <Typography variant="h6">₹ {paymentData[0]?.total_price}</Typography>
          </Grid>
    </Grid>
            </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>           
        <Grid container sx={{p: 2, pb: 8, background:'#eaeaea'}}>
                  <Grid item xs={6}>
                  <Typography>Signature customer</Typography>
            </Grid>
            <Grid item xs={5} sx={{textAlign:'end'}}>
            <Typography>Signature authority</Typography>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Viewchallan;