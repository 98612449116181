import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useNavigate } from "react-router-dom";
import { createenquiry } from "../../slices/enquiry";
import { getregion } from "../../slices/setting";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const Addenquiry = ({ setLoader, show }) => {

  const user = JSON.parse(localStorage.getItem('user'));

  const [enquirydata, setEnquirydata] = useState({
    dealer_id:user.dealer_id,
    created_by:user.organization_name,
    enquiry_type:"HOT",
    customer_type:"Business",
    title:"Mr",
    first_name:"",
    last_name:"",
    phone:"",
    alt_phone:"",
    gst_number:"",
    email:"",
    location:"",
    subject:"",
    note:"",
    make_schedule: false,
    schedule: dayjs(),
    previous_schedules:[]
  })

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)

    dispatch(createenquiry({...enquirydata, schedule: enquirydata.make_schedule ? (enquirydata.schedule).format('YYYY-MM-DD HH:mm:ss') : null}))
    .then(() => {
      navigate("/enquiry");
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Enquire</Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={4}>
                <Typography >Enquiry Type *</Typography>
                </Grid>
                <Grid item xs={8}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={enquirydata.enquiry_type}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        enquiry_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="HOT"
                    control={<Radio />}
                    label="HOT"
                  />
                  <FormControlLabel
                    value="COLD"
                    control={<Radio />}
                    label="COLD"
                  />
                  <FormControlLabel
                    value="WARM"
                    control={<Radio />}
                    label="WARM"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={4}>
                <Typography >Customer Type *</Typography>
                </Grid>
                <Grid item xs={8}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={enquirydata.customer_type}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        customer_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Business"
                    control={<Radio />}
                    label="Business"
                  />
                  <FormControlLabel
                    value="Individual"
                    control={<Radio />}
                    label="Individual"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={4}>
                <Typography >Name *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <FormControl size="small"  sx={{ minWidth: 80 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={enquirydata.title}
                    onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        title: e.target.value,
                      })
                    }
                  >
                    <MenuItem selected value="Mr">
                      Mr
                    </MenuItem>
                    <MenuItem value="Miss">Miss</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </Select>
                </FormControl>{" "}
                <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="First Name"
                  variant="outlined"
                  value={enquirydata.first_name}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        first_name: e.target.value,
                      })
                    }
                />
                <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="Last Name"
                  variant="outlined"
                  value={enquirydata.last_name}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        last_name: e.target.value,
                      })
                    }
                />
              </Box>
                </Grid>
                <Grid item xs={4}>
                <Typography>Phone Number *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={enquirydata.phone}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        phone: e.target.value,
                      })
                    }
              />
                </Grid>
                {enquirydata.customer_type === "Business" ?
                <>
                <Grid item xs={4}>
                <Typography >GST Number *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={enquirydata.gst_number}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        gst_number: e.target.value,
                      })
                    }
              />
                              </Grid>
                              </>
              :
              <>
              <Grid item xs={4}>
                <Typography >Alt Number *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={enquirydata.alt_phone}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        alt_phone: e.target.value,
                      })
                    }
              />
              </Grid>
              </>
                }
                <Grid item xs={4}>
                <Typography >Email ID *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="test@gmail.com"
                variant="outlined"
                value={enquirydata.email}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        email: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Location *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={enquirydata.location}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        location: e.target.value,
                      })
                    }
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
          <Grid container spacing={2}>
          <Grid item xs={4}>
                <Typography >Subject *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={enquirydata.subject}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        subject: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Note *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                rows={3}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={enquirydata.note}
                onChange={(e) =>
                      setEnquirydata({
                        ...enquirydata,
                        note: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={12}>
                <FormGroup>
  <FormControlLabel required control={<Checkbox 
    checked={enquirydata.make_schedule}
    onChange={(e)=>{
      setEnquirydata({
                        ...enquirydata,
                        make_schedule: e.target.checked,
            })
    }}
  />} label="Make Schedule for Call Back" />
</FormGroup>
                </Grid>
                {enquirydata.make_schedule && 
                <>
                <Grid item xs={4}>
                <Typography >Schedule *</Typography>
                </Grid>
                <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileDateTimePicker']}>
        <DateTimePicker  
           value={enquirydata.schedule}
                onChange={(newValue) =>
                      setEnquirydata({
                        ...enquirydata,
                        schedule: newValue,
                      })
                    }
        />
      </DemoContainer>
    </LocalizationProvider>
                </Grid>
                </>
                }
          </Grid>
          </Grid>
        </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Enquiry</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Addenquiry;
