import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));

const createNewenquiry = async (data) => {
  const response = await axios.post(API_URL + "api/enquiry", { ...data }, { headers: authHeader() });
  return response.data;
};

const getEnquiry = async () => {
  const response = await axios.get(API_URL + `api/enquiry/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const updateEnquiry = async (e_id, formData) => {
  const response = await axios.put(API_URL + `api/enquiry/id/${e_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteEnquiry = async (e_id) => {
  const response = await axios.delete(API_URL + `api/enquiry/id/${e_id}`, { headers: authHeader() });
  return response.data;
};

const enquiryService = {
  createNewenquiry,
  getEnquiry,
  updateEnquiry,
  deleteEnquiry
};

export default enquiryService