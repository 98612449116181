import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  Card,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import {
  createproduct,
  createspare,
  getaccessory,
  getproduct,
  getspare,
} from "../../slices/product";
import BasicTabs from "../../components/tabs/Tabs4";
// import './style.css';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  createpurchase,
  getpurchasebyid,
  updatepurchasebyid,
} from "../../slices/purchase";
import { Stepper, Step, StepLabel } from "@mui/material";
import Modal from "@mui/material/Modal";
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTable2 from "../../components/table/Table2";
import { createaccessorystock, createproductstock, createsparestock } from "../../slices/inventory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const columns1 = [
  { id: 'no', label: '#', minWidth: 150, align:'start' },
  { id: 'vechile_stocks_id', label: 'Inventory No', minWidth: 150, align:'start' },
  { id: 'chasis_no', label: 'Chassis No', minWidth: 150, align:'center' },
  { id: 'motor_no', label: 'Motor No', minWidth: 150, align:'center' },
  { id: 'battery_no', label: 'Battery No', minWidth: 150, align:'center' },
  { id: 'controller_no', label: 'Controller No', minWidth: 150, align:'center' }
];

const columns11 = [
  { id: 'no', label: '#', minWidth: 150, align:'start' },
  { id: 'product', label: 'product', minWidth: 150, align:'start' },
  { id: 'company', label: 'company', minWidth: 150, align:'center' },
  { id: 'part_code', label: 'Part No', minWidth: 150, align:'center' },
];

const steps = [
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  "Step 7",
];

const Purchasedetail = ({ setLoader, show }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const params = useParams();

  const [activeStep, setActiveStep] = useState(1);

  const [transadat, setTransadat] = useState({
    payment_method: "",
    transaction_id: "",
    amount: "",
    transaction_date: "",
    note: "",
  });

  const dispatch = useDispatch();

  const { detail: purchaseDetail } =
    useSelector((state) => state.purchase) || [];

  useEffect(() => {
    setActiveStep(
      !purchaseDetail?.order_created
        ? 0
        : !purchaseDetail?.order_approved
        ? 1
        : !purchaseDetail?.payment_done
        ? 2
        : !purchaseDetail?.payment_verification
        ? 3
        : !purchaseDetail?.assign_products
        ? 4
        : !purchaseDetail?.dispatch_products
        ? 5
        : !purchaseDetail?.out_for_delivery
        ? 6
        : 7
    );
  }, [purchaseDetail]);

  useEffect(() => {
    setLoader(true);
    dispatch(getpurchasebyid(params.purchaseID))
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handlePayment = (e) => {
    e.preventDefault();
    const formData = {
      transaction: { ...transadat },
      payment_done: true,
      payment_done_date: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
    const p_id = params.purchaseID;
    setLoader(true);
    dispatch(updatepurchasebyid({ p_id, formData }))
      .then(() => {
        setLoader(false);
        handleClose();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  console.log(purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail.assign_products_list));

  const inveData = {
    data: purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail?.assign_products_list).map(obj =>{return {
      vehicle_id: obj.vehicle_id,
      variant_id: obj.variant_id,
      stock_id: obj.stock_id,
    }})
  }

  const inveData2 = {
    data: purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail?.assign_products_list).map(obj =>{return {
      vehicle_id: obj.vehicle_id,
      variant_id: obj.variant_id,
      stock_id: obj.stock_id,
      spare_id: purchaseDetail?.item_list.filter(ab => ab.product_name === obj.product)[0].id
    }})
  }

  const inveData3 = {
    data: purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail?.assign_products_list).map(obj =>{return {
      vehicle_id: obj.vehicle_id,
      variant_id: obj.variant_id,
      stock_id: obj.stock_id,
      accessory_id: purchaseDetail?.item_list.filter(ab => ab.product_name === obj.product)[0].id
    }})
  }

  console.log(purchaseDetail);

  const handleReceive = (e) => {
    e.preventDefault();
    const formData = {
      out_for_delivery: true,
      out_for_delivery_date: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
    const p_id = params.purchaseID;
    setLoader(true);
    dispatch(updatepurchasebyid({ p_id, formData }))
      .then(() => {
        setLoader(false);
        if(purchaseDetail?.product_type === "Vehicle"){
          dispatch(createproductstock(inveData))
        }
        if(purchaseDetail?.product_type === "Spare"){
          dispatch(createsparestock(inveData2))
        }
        if(purchaseDetail?.product_type === "Accessory"){
          dispatch(createaccessorystock(inveData3))
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box>
        <Grid
          container
          spacing={3}
          sx={{ paddingBottom: 2, alignItems: "start" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>{params.purchaseID}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: 3 }}>
            <Card sx={{ p: 2 }}>
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={3}>
                  <Typography variant="h6">Purchase Date</Typography>
                  <Typography variant="p">
                    {new Date(purchaseDetail?.created_at).getDate()} /{" "}
                    {new Date(purchaseDetail?.created_at).getMonth() + 1} /{" "}
                    {new Date(purchaseDetail?.created_at).getFullYear()}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">Quantity</Typography>
                  <Typography variant="p">
                    {purchaseDetail?.item_list?.length}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">Total Order Value</Typography>
                  <Typography variant="p">
                    {purchaseDetail?.item_list
                      .map((obj) => Number(obj.total_price))
                      ?.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6">PO Secret</Typography>
                  <Typography variant="p">
                    {purchaseDetail?.po_secret}
                  </Typography>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" sx={{ my: 2 }} flexItem />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">PO Status</Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{ my: 5 }}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <Typography variant="body1">
                            {label === "Step 1" &&
                              (purchaseDetail?.order_created
                                ? "Order Created"
                                : "Order Not Created")}
                            {label === "Step 2" &&
                              (purchaseDetail?.order_approved
                                ? "Order Approved"
                                : "Waiting for Approval")}
                            {label === "Step 3" &&
                              (purchaseDetail?.payment_done
                                ? "Payment Done"
                                : "Pending Payment")}
                            {label === "Step 4" &&
                              (purchaseDetail?.payment_verification
                                ? "Payment Verified"
                                : "Verification")}
                            {label === "Step 5" &&
                              (purchaseDetail?.assign_products
                                ? "Product Assigned"
                                : "Product Assign")}
                            {label === "Step 6" &&
                              (purchaseDetail?.dispatch_products
                                ? "Dispatched"
                                : "Dispatch Products")}
                            {label === "Step 7" &&
                              (purchaseDetail?.out_for_delivery
                                ? "Received"
                                : "Out for delivery")}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            {label === "Step 1" &&
                              (purchaseDetail?.order_created
                                ? purchaseDetail?.order_created_date
                                : null)}
                            {label === "Step 2" &&
                              (purchaseDetail?.order_approved
                                ? purchaseDetail?.order_approved_date
                                : null)}
                            {label === "Step 3" &&
                              (purchaseDetail?.payment_done
                                ? purchaseDetail?.payment_done_date
                                : null)}
                            {label === "Step 4" &&
                              (purchaseDetail?.payment_verification
                                ? purchaseDetail?.payment_verification_date
                                : null)}
                            {label === "Step 5" &&
                              (purchaseDetail?.assign_products
                                ? purchaseDetail?.assign_products_date
                                : null)}
                            {label === "Step 6" &&
                              (purchaseDetail?.dispatch_products
                                ? purchaseDetail?.dispatch_products_date
                                : null)}
                            {label === "Step 7" &&
                              (purchaseDetail?.out_for_delivery
                                ? purchaseDetail?.out_for_delivery_date
                                : null)}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>{" "}
                </Grid>
                {purchaseDetail?.payment_done &&
                <Grid item xs={12} sx={{mb: 3}}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Transaction ID
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.transaction_id}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Method
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.payment_method}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Amount
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.amount}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Date
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.transaction_date}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Note
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.note}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Verified
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.payment_verification ? <VerifiedIcon sx={{color:'green'}}/> : <HighlightOffIcon sx={{color:'red'}}/> }
                        </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
              </Grid>
              <Divider orientation="horizontal" sx={{ mb: 2 }} flexItem />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">Items List</Typography>
                </Grid>
                {purchaseDetail?.order_approved &&
                  !purchaseDetail?.payment_done && (
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      <Button onClick={handleOpen} variant="contained">
                        Make Payment
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Payment
                          </Typography>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handlePayment}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{ alignItems: "center" }}
                            >
                              <Grid item xs={4}>
                                <Typography>Payment Method</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl size="small" fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={setTransadat.payment_method}
                                    onChange={(e) =>
                                      setTransadat({
                                        ...transadat,
                                        payment_method: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="Card">Card</MenuItem>
                                    <MenuItem value="UPI">UPI</MenuItem>
                                    <MenuItem value="Check">Check</MenuItem>
                                    <MenuItem value="RTGS">RTGS</MenuItem>
                                    <MenuItem value="IMBS">IMBS</MenuItem>
                                    <MenuItem value="NEFT">NEFT</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Transaction ID *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={transadat.transaction_id}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      transaction_id: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Amount</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={transadat.amount}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      amount: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Date of transaction</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  type="date"
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  placeholder="GST988767567"
                                  variant="outlined"
                                  value={transadat.transaction_date}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      transaction_date: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Note</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={transadat.note}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      note: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" type="submit">
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </Grid>
                  )}
                  {purchaseDetail?.dispatch_products &&
                  !purchaseDetail?.out_for_delivery && (
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      <Button onClick={handleReceive} variant="contained">
                        Mark As Received
                      </Button>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  {purchaseDetail?.item_list.map((ec, index) => {
                    return (
                      <Card
                        key={index}
                        sx={{
                          boxShadow: "none",
                          border: "1px solid #bababa",
                          mb: 2,
                        }}
                      >
                        <Grid container rowSpacing={2} sx={{ p: 2 }}>
                          <Grid item xs={1.5}>
                            <img
                              src={ec.product_img}
                              alt="img"
                              width={60}
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">Vehicle</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {ec.vechile_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">Variant</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {ec.variant_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">
                              Ordered color
                            </Typography>
                            <Box sx={{ display: "flex", gap: "8px" }}>
                              <p
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "30px",
                                  background: `${ec.ordered_color}`,
                                  margin: 0,
                                }}
                              ></p>
                            </Box>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">
                              Approved color
                            </Typography>
                            <Box sx={{ display: "flex", gap: "8px" }}>
                              <p
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "30px",
                                  background: `${ec.approved_color}`,
                                  margin: 0,
                                }}
                              ></p>
                            </Box>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">Ordered Qty</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {ec.ordered_qty}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">
                              Approved Qty
                            </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {ec.approved_qty}
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5}>
                            <Typography variant="body1">Total Price</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              ₹ {ec.total_price}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          {purchaseDetail?.assign_products &&
              <CustomTable2 columns={purchaseDetail?.product_type === "Vehicle" ? columns1 : purchaseDetail?.product_type === "Spare" ? columns11 : columns11 } rows={ purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail?.assign_products_list).filter(obj =>{return obj.vechile_name === ec.vechile_name})}/>
              }
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Purchasedetail;
