import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));

const createProductStock = async (data) => {
  const response = await axios.post(API_URL + "api/dealer_vehicle_inventory", { ...data, "dealer_id": user.dealer_id }, { headers: authHeader() });
  return response.data;
};

const createSpareStock = async (data) => {
  const response = await axios.post(API_URL + "api/dealer_spare_inventory", { ...data, "dealer_id": user.dealer_id }, { headers: authHeader() });
  return response.data;
};

const createAccessoryStock = async (data) => {
  const response = await axios.post(API_URL + "api/dealer_accessory_inventory", { ...data, "dealer_id": user.dealer_id }, { headers: authHeader() });
  return response.data;
};

const getProductStock = async () => {
  const response = await axios.get(API_URL + `api/dealer_vehicle_inventory/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const getSpareStock = async () => {
  const response = await axios.get(API_URL + `api/dealer_spare_inventory/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const getAccessoriesStock = async () => {
  const response = await axios.get(API_URL + `api/dealer_accessory_inventory/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const deleteProductStock = async (inv_id) => {
  const response = await axios.delete(API_URL + `api/dealer_vehicle_inventory/${inv_id}`, { headers: authHeader() });
  return response.data;
};

const deleteSpareStock = async (inv_id) => {
  const response = await axios.delete(API_URL + `api/dealer_spare_inventory/${inv_id}`, { headers: authHeader() });
  return response.data;
};

const deleteAccessoryStock = async (inv_id) => {
  const response = await axios.delete(API_URL + `api/dealer_accessory_inventory/${inv_id}`, { headers: authHeader() });
  return response.data;
};

const createServicePrice = async (data) => {
  const response = await axios.post(API_URL + "api/service_price", { ...data, "dealer_id": user.dealer_id }, { headers: authHeader() });
  return response.data;
};

const getServicePriceBydealer = async () => {
  const response = await axios.get(API_URL + `api/service_price/dealer/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const getServicePriceByid = async (ser_id) => {
  const response = await axios.get(API_URL + `api/service_price/id/${ser_id}`, { headers: authHeader() });
  return response.data;
};

const updateServicePricebyId = async (ser_id, formData) => {
  const response = await axios.put(API_URL + `api/service_price/${ser_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteServicePricebyId = async (ser_id, formData) => {
  const response = await axios.delete(API_URL + `api/service_price/${ser_id}`, { headers: authHeader() });
  return response.data;
};


const inventoryService = {
  createProductStock,
  createSpareStock,
  createAccessoryStock,
  getProductStock,
  getSpareStock,
  getAccessoriesStock,
  deleteProductStock,
  deleteSpareStock,
  deleteAccessoryStock,
  createServicePrice,
  getServicePriceBydealer,
  getServicePriceByid,
  updateServicePricebyId,
  deleteServicePricebyId
};

export default inventoryService