import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));

const createNewQuote = async (data) => {
  const response = await axios.post(API_URL + "api/quote", { ...data }, { headers: authHeader() });
  return response.data;
};

const getQuotebyDealer = async () => {
  const response = await axios.get(API_URL + `api/quote/dealer/${user.dealer_id}`, { headers: authHeader() });
  return response.data;
};

const getQuotebyId = async (id) => {
  const response = await axios.get(API_URL + `api/quote/quote/${id}`, { headers: authHeader() });
  return response.data;
};

const updateQuotebyId = async (inv_id, formData) => {
  const response = await axios.put(API_URL + `api/quote/${inv_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};


const quoteService = {
  createNewQuote,
  getQuotebyDealer,
  getQuotebyId,
  updateQuotebyId
};

export default quoteService