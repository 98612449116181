import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import settingReducer from "./slices/setting";
import enquiryReducer from "./slices/enquiry";
import productReducer from "./slices/product";
import inventoryReducer from "./slices/inventory";
import challanReducer from "./slices/challan";
import  purchaseReducer from "./slices/purchase";
import  customerReducer from "./slices/customer";
import dealerReducer from "./slices/dealer";
import quoteReducer from "./slices/quote";


const reducer = {
  auth: authReducer,
  message: messageReducer,
  setting: settingReducer,
  enquiry: enquiryReducer,
  customer: customerReducer,
  product: productReducer,
  inventory: inventoryReducer,
  challan: challanReducer,
  purchase: purchaseReducer,
  dealer: dealerReducer,
  quote: quoteReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})

export default store;