import React, { useEffect , useState} from 'react';
import { Box, Grid, Button, Typography, TextField, Divider, Card, Avatar } from '@mui/material';
import CustomTable from '../../components/table/Table';
import Adddealer from './Addenquiry';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import {  getenquiry } from '../../slices/enquiry';
import { deepPurple } from '@mui/material/colors';
import { Filter } from '@mui/icons-material';
import no_data from "../../assets/images/no_data.png";

const Enquiry = ({setLoader, show}) => {

  const [filter, setFilter] = useState('All')

  const {enquiry: enquiryData} = useSelector((state) => state.enquiry);

  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    const filterData = () => {
      const currentDate = new Date();
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(currentDate);
      endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 6);
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      const todayData = enquiryData.filter(item => isSameDay(new Date(item.schedule), currentDate));
      const thisWeekData = enquiryData.filter(item => isBetween(new Date(item.schedule), startOfWeek, endOfWeek));
      const thisMonthData = enquiryData.filter(item => isBetween(new Date(item.schedule), startOfMonth, endOfMonth));

      if(filter === "All"){
        setFilteredData(enquiryData)
      }
      if(filter === "Today Follow Up"){
        setFilteredData(todayData)
      }
      if(filter === "This Week Follow Up"){
        setFilteredData(thisWeekData)
      }
      if(filter === "This Month Follow Up"){
        setFilteredData(thisMonthData)
      }
    };

    filterData();
  }, [enquiryData, filter]);

  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

  const isBetween = (date, startDate, endDate) => {
    return date >= startDate && date <= endDate;
  };



  // console.log(enquiryData);

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getenquiry())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            Enquire List
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Enquiry"/>
          <Link to='/enquiry/new'>
          <Button variant='contained' size='large'>+ New Enquire</Button>
          </Link>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2}}>
              <Box sx={{cursor:'pointer',border:'1.5px solid', px: 2, py: 1, borderRadius:'20px', backgroundColor: filter === "All" ? '#eef8ff' : '#fff', borderColor: filter === "All" ? '#0b4c7c' : '#000', }} onClick={()=> setFilter("All")}>All</Box>
              <Box sx={{cursor:'pointer', border:'1.5px solid', px: 2, py: 1, borderRadius:'20px', backgroundColor: filter === "Today Follow Up" ? '#eef8ff' : '#fff', borderColor: filter === "Today Follow Up" ? '#0b4c7c' : '#000', }} onClick={()=> setFilter("Today Follow Up")}>Today Follow Up</Box>
              <Box sx={{cursor:'pointer', border:'1.5px solid', px: 2, py: 1, borderRadius:'20px',
              backgroundColor: filter === "This Week Follow Up" ? '#eef8ff' : '#fff', borderColor: filter === "This Week Follow Up" ? '#0b4c7c' : '#000', }} onClick={()=> setFilter("This Week Follow Up")}>This Week Follow Up</Box>
              <Box sx={{cursor:'pointer', border:'1.5px solid', px: 2, py: 1, borderRadius:'20px', backgroundColor: filter === "This Month Follow Up" ? '#eef8ff' : '#fff', borderColor: filter === "This Month Follow Up" ? '#0b4c7c' : '#000', }} onClick={()=> setFilter("This Month Follow Up")}>This Month Follow Up</Box>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{mt: 2}}>
        <Grid container spacing={2}>
        {filteredData.length === 0 ? 
        <Grid item xs={12} display="flex" justifyContent='center'>
          <img src={no_data} alt='no_data'></img>
        </Grid>
        :
        filteredData.map((va, index)=>{
          return(
            <Grid key={index} item xs={12}>
            <Link to={`/enquiry/view/${va.id}`} style={{textDecoration: 'none'}}>
                  <Card sx={{boxShadow:'none', p: 2, border:'1px solid #eaeaea', display:'flex', justifyContent:'space-between', alignItems:'center', borderLeft:'3px solid #0b4c7c'}}>
                  <Grid container spacing={2} sx={{alignItems:'center'}}>
                      <Grid item xs={1}>
                      <Avatar sx={{ bgcolor: deepPurple[500] }}>{(va.first_name)[0]}{(va.last_name)[0]}</Avatar>
                      </Grid>
                      <Grid item xs={2}>
                      <Box>
                      <Typography variant='p'>{va.title} {va.first_name} {va.last_name}</Typography>
                      <Typography variant='body2'>Contacted: {`${new Date(va.created_at).getDate()}-${new Date(va.created_at).getMonth()}-${new Date(va.created_at).getFullYear()}`}</Typography>
                    </Box>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant='p'>{va.phone}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant='p'>{va.location}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant='p'>{va.make_schedule ? va.schedule : ""}</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{textAlign:'center'}}>
                        <Typography variant='p' sx={{bgcolor:'#0b4c7c', color:'#fff', px:1, borderRadius:'10px'}}>{va.enquiry_type}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{textAlign:'end'}}>
                        <Typography variant='p' sx={{bgcolor:'#0b7c22', color:'#fff', px:1, borderRadius:'10px'}}>Follow up</Typography>
                        </Grid>
                  </Grid>
                  </Card>
                  </Link>
              </Grid>
          )
        })}
        </Grid>
      </Box>
      </Box>
    </div>
  );
}

export default Enquiry;