import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Quotetable';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import { Link } from 'react-router-dom';
import { getchallan } from '../../slices/challan';
import Popup from '../../components/popup/Popup';
import { getquotebydealer } from '../../slices/quote';

const columns = [
    { id: 'quote_id', label: 'Quote ID', minWidth: 150, align:'start' },
    { id: 'customer_name', label: 'Customer Name', minWidth: 150, align:'start' },
    { id: 'phone_no', label: 'Phone No', minWidth: 150, align:'center' },
    { id: 'email', label: 'Email ID', minWidth: 150, align:'center' },
    { id: 'total', label: 'Amount', minWidth: 150, align:'center' },
    // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];

const Quote = ({setLoader, show}) => {

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const {quote: quoteData} = useSelector((state) => state.quote) || [];

  const challanUpdata = quoteData?.map((va)=>{
    return{
      ...va,
    }
  })

  const dispatch = useDispatch();

  const {message: message} = useSelector((state) => state.challan);

  console.log(message);

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  useEffect(()=>{
    setLoader(true)
      dispatch(getquotebydealer())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
              <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            Quotation
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          {/* <SearchButton placeholder="Search Here"/> */}
          <Link to='addquote'>
          <Button variant='contained' size='large' >+ Create New Quote</Button>
          </Link>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable setLoader={setLoader} columns={columns} rows={challanUpdata ? challanUpdata : []} />
      </Box>
    </div>
  );
}

export default Quote;